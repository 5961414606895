import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Note {
  id?: string;
  title?: string;
  name?: string;
  image?: string;
  file?: string;
  src?: string;
  images?: string[];
}

interface NoteSliceState {
  sturmNotes: Note[];
  noteImages: string[];
  notesConvert: Note[];
  selectedNote: Object;
  strumString: string;
  midiFile: string;

}

const initialState: NoteSliceState = {
  
  sturmNotes: localStorage.getItem('sturmNotes') ? JSON.parse((localStorage.getItem('sturmNotes') || "")) : [],
  noteImages: [],
  notesConvert: localStorage.getItem('sturmConvertNotes') ? JSON.parse((localStorage.getItem('sturmConvertNotes') || "")) : [],
  selectedNote: localStorage.getItem('selected') ? JSON.parse((localStorage.getItem('selected') || "")) : {},
  strumString:  localStorage.getItem('strumString') ? JSON.parse((localStorage.getItem('strumString') || "")) : "",
  midiFile: ''
};
export const noteSlice = createSlice({
  name: "sturmNotes",
  initialState,
  reducers: {
    addNote: (state, action: PayloadAction<string>) => {
      state.sturmNotes = [
        ...state.sturmNotes,
        {
          name: action.payload,
        },
      ];
    },
    addStrum: (state, action) => {
      state.strumString = state.strumString.concat(action.payload)
      localStorage.setItem('strumString', JSON.stringify(state.strumString));
    },
    setNoteImages: (state, action: PayloadAction<string>) => {
        state.noteImages = [action.payload];
      },
    setSturmNotes: (state, action: PayloadAction<Note>) => {
      state.sturmNotes = [...state.sturmNotes, action.payload];
      localStorage.setItem('sturmNotes', JSON.stringify(state.sturmNotes));
    },
    setConvertNotes: (state, action: PayloadAction<Note>) => {
      state.notesConvert = [action.payload];
      localStorage.setItem('sturmConvertNotes', JSON.stringify(state.notesConvert));
    },
    setSturmsNotes: (state, action: PayloadAction<Note>) => {
        state.sturmNotes = [...state.sturmNotes, action.payload];
      },
    setCheckedNotes: (state, action: PayloadAction<string>) => {
        state.selectedNote = action.payload;
        localStorage.setItem('selected', JSON.stringify(state.selectedNote));
    },
    setStrumString: (state, action: PayloadAction<string>) => {
      state.strumString = action.payload;
      localStorage.setItem('strumString', JSON.stringify(state.strumString));
    },
    setURL: (state, action: PayloadAction<string>) => {
     state.midiFile = action.payload;
    },
    removeNote: (state, action: PayloadAction<string>) => {
      state.sturmNotes = state.sturmNotes.filter((note) => note.src !== action.payload)
     },
  },
});
export const { addNote, setSturmNotes, setSturmsNotes, setNoteImages, setCheckedNotes, setConvertNotes, setStrumString, addStrum, setURL, removeNote } = noteSlice.actions;

export const store = configureStore({
  reducer: {
    sturmNotes: noteSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const selectNotes = (state: RootState) => state.sturmNotes.sturmNotes;
export const selectedNotes = (state: RootState) => state.sturmNotes.selectedNote;
export const selectConvertNote = (state: RootState) => state.sturmNotes.notesConvert;
export const selectStrumString = (state: RootState) => state.sturmNotes.strumString;
export const getURL = (state: RootState) => state.sturmNotes.midiFile;

