import React from 'react'
import { Modal, Button, Form, FloatingLabel } from 'react-bootstrap';
import styled from 'styled-components'


const ModalContainer = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

}

interface ModalProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    show?: boolean;
    handleClose?: () => void;
    onClick?: () => void;
}

function MidiModal(props: ModalProps) {

    const { show, handleClose, onChange, onClick } = props
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header className="border-0 pb-0" closeButton>
                <h1>Strum playground</h1>
            </Modal.Header>
            <Modal.Body>
                <FloatingLabel controlId="floatingTextarea2" label="Notes">
                    <Form.Control
                        as="textarea"
                        placeholder="Enter les notes ex: A1 A2 C3 ..."
                        style={{ height: '100px' }}
                        onChange={onChange}
                    />
                </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose}>Quiter</Button>
                <Button variant="success" onClick={onClick}>Générer</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default MidiModal