import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Card from 'components/card';
import MIDISounds from "midi-sounds-react";
import { StrumPlayer } from "components/player";
import { useForm } from "react-hook-form";
import melodyNotes from 'data/melodyNotes';
import { useSelector, useDispatch } from 'react-redux'
import { setSturmNotes, selectNotes, setNoteImages, setCheckedNotes, selectedNotes, setStrumString } from 'store/store'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Button, Modal, Alert, InputGroup, FormControl } from 'react-bootstrap';
import { Midi } from '@tonejs/midi'
import './style.css'

const CardItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-bottom: 15px;
    user-select: none;
    border: none;
    & > * {
        margin: 0.2rem 0.2rem;
    }
    svg { 
        display: none;
    }

`;

const MelodyNote = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-bottom: 15px;
    user-select: none;
    border: none;
    & > * {
        margin: 0.2rem 0.2rem;
    }
`;
const CardItemsTitle = styled.legend`
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: #9c9c9c;
    text-align: center;
    line-height: 1.125;
    margin-bottom: 1rem;
    border: 2px solid #e2e2e2;
    flex-wrap: wrap;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5px 0;;
    a{
        margin-right: 20px;
    }
`;


let midiSounds: any;

function CardItems() {

    const [show, setShow] = useState<boolean>();
    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const [searchInput, setSearchInput] = useState<string>('');
    const [melodyNote, setMelodyNote] = useState<any>();

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    //const [activeState, setActiveState] = useState<any>({ checkedItems: [new Map()] })

    const [strums, setStrums] = useState<any>([]);
    const [notes, setNotes] = useState<any>([]);
    const [checkedItems, setCheckedItems] = useState<any>({});

    const sturmNotes = useSelector(selectedNotes);
    const sturmSelectNotes = useSelector(selectNotes);
    const dispatch = useDispatch();

    useEffect(() => {
        setMelodyNote(melodyNotes)
    }, [])

    useEffect(() => {
        dispatch(setCheckedNotes(checkedItems));
        dispatch(setSturmNotes(notes));
        dispatch(setNoteImages(notes.image));
        dispatch(setStrumString(strums))
    }, [dispatch, notes, checkedItems, strums]);

    useEffect(() => {
        setCheckedItems(sturmNotes);

    }, [])

    useEffect(() => {
        _.map((sturmSelectNotes), (note) => {
            setNotes(note);
        })
    }, [])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const isChecked = e.target.checked;
        const value = e.target.value;

        setCheckedItems({
            ...checkedItems,
            [name]: isChecked
        });

        if (isChecked) {
            setStrums((prevState: any) => [...prevState, value]);
        } else {
            setStrums(strums.filter((item: any) => item !== value));
        }

    }

    



    const selectItem = (event: any, note: any) => {
        const isChecked = event.target.checked;
        const value = event.target.value;
        if (isChecked) {
            setNotes((prevState: any) => [...prevState, { src: note.image, width: 4, height: 3, id: Date.now() + Math.random() }])
            /*  var player = new StrumPlayer(midiSounds, value);
             player.play(); */
        } else {
            let newItems = notes.filter((i: any) => i.src !== note.image)
            setNotes(newItems)
        }

    }

    const handleRestClick = () => {
        setNotes([]);
        setStrums([]);
        setCheckedItems(false)
    }


    const onSubmit = (data: any) => {
        console.log("submit clicked!!!");
    };
    const { handleSubmit, register } = useForm();

    const [noteTypes, setNoteTypes] = useState([
        'Noire', 'Noire Pointée', 'Croche', 'Croche Pointée', 'Blanche', 'Blanche pointée', 'Ronde', 'Lettre'
    ])
    const [selectNoteType, setSelectNoteType] = useState<string>('Noire')


    const handleRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectNoteType(e.target.value)
        const value = e.target.value
        console.log("Element", e.target.value)
    }


    const options = noteTypes.map((note, key) => {
        const isCurrent = selectNoteType === note;
        const uniqueId = `note-${key}-${note}`;
        return (
            <div key={key} className="radioPad">
                <div>
                    <label
                        htmlFor={uniqueId}
                        className={
                            isCurrent
                                ? 'radioPad__wrapper radioPad__wrapper--selected'
                                : 'radioPad__wrapper'
                        }
                    >
                        <input
                            className="radioPad__radio"
                            type="radio"
                            name="noteTypes"
                            id={uniqueId}
                            value={note}
                            onChange={handleRadio}
                        />
                        {note}
                    </label>
                </div>
            </div>
        );
    });


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <CardItemsContainer>
                <CardItemsTitle>
                    {options}

                </CardItemsTitle>
                <ButtonContainer>
                    <Link to={!notes.length ? '/' : '/notes'} onClick={handleShow}>
                        <Button variant="success" size="lg">
                            Copier Les notes
                        </Button>
                    </Link>
                    <Button variant="warning" size="lg" onClick={handleRestClick}>
                        Réinitialiser
                    </Button>
                </ButtonContainer>

                <MelodyNote>
                    {(melodyNote || []).map((note: any, index: any) => {
                        const { title, image, file, name, type } = note;
                        if(type.toLowerCase() == selectNoteType.toLowerCase()) {
                            return (
                                <Card key={index} {...register("card")} checked={checkedItems[title]} onChange={handleChange} onClick={(event: React.MouseEvent<HTMLElement>) => {
                                    selectItem(event, note)
                                }} {...{ title, name, image, file }} />
                            )
                        }
                    })}
                </MelodyNote>

                <MIDISounds
                    ref={(ref: any) => (midiSounds = ref)}
                    appElementName="root"
                    instruments={[6]}
                />
            </CardItemsContainer>

            {isEmpty && <EmptyModal show={show} handleClose={handleClose} onChange={handleShow} />}

        </form>
    )
}

interface EmptyModalProps {

    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    show?: boolean;
    handleClose?: () => void;
}
const EmptyModal = (props: EmptyModalProps) => {

    const { handleClose, show } = props;
    return (
        <Modal onHide={handleClose} show={show}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="alert alert-danger">
                    Sélectionner au moins une note
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={handleClose}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function AlertDismissible(props: EmptyModalProps) {

    const { handleClose } = props

    return (
        <Alert variant="danger" onClose={handleClose} dismissible>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
                Change this and that and try again. Duis mollis, est non commodo
                luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
                Cras mattis consectetur purus sit amet fermentum.
            </p>
        </Alert>
    );
}

export default CardItems
