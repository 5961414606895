import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import ListTracks from 'components/listTracks';
import CardItems from 'components/cardItems';
import ConvertImages from 'components/convert';
import 'index.css'


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<CardItems />} />
        <Route path="/notes" element={<ListTracks />} />
        <Route path="/convert-notes" element={<ConvertImages />} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
