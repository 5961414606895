import React from 'react'
import styled from 'styled-components'
import { INote } from 'interfaces/note'

interface INotes extends INote {
  onClick?: (event: React.MouseEvent<HTMLElement>) =>  void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  getValues?: () => void;
  checked?: boolean;
  id?: any;
}

const CardContainer = styled.div``;
const Cardwrapper = styled.label``;

const CardTile = styled.span`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 7.5rem;
    min-height: 7rem;
    border-radius: 0.5rem;
    border: 2px solid #dde2f2;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(#000, 0.1);
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
    &:before {
    content: "";
    position: absolute;
    display: block;
    width: 1.15rem;
    height: 1.15rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='192' height='192' fill='%23FFFFFF' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Cpolyline points='216 72.005 104 184 48 128.005' fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'%3E%3C/polyline%3E%3C/svg%3E");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    }
    &:hover {
    border-color: #2260ff;
    &:before {
        transform: scale(1);
        opacity: 1;
    }
`;

const CardImage = styled.span`
    transition: 0.375s ease;
    color: #494949;
    img {
      width: 7rem;
      height: 6.2rem;
      object-fit: contain;
      margin-top: -10px;
      z-index: 0;
      position: relative;
    }
`;

const CardLabel = styled.span`
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
    font-size: 13px;
    padding-bottom: 2.3rem;
    /* z-index: 1; */
    position: absolute;
    bottom: -35px;
`;

const CardInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  ${({ checked }) => checked && `
  &:checked + ${CardTile} {
    border-color: #2260ff;
    box-shadow: 0 5px 10px rgba(#000, 0.1);
    color: #2260ff;
    &:before {
      transform: scale(1);
      opacity: 1;
      background-color: #2260ff;
      border-color: #2260ff;
    }
  `}
  
    ${CardImage},
    ${CardLabel} {
      color: #2260ff;
    }
  }
  &:focus + ${CardTile} {
    border-color: #2260ff;
    box-shadow: 0 5px 10px rgba(#000, 0.1), 0 0 0 4px #b5c9fc;
    &:before {
      transform: scale(1);
      opacity: 1;
    }
  }
`;



function Card(props: INotes) {

    const { name, title, image, onClick, onChange, checked } = props;
    return (
        <CardContainer>
            <Cardwrapper>
                <CardInput type="checkbox" name={title} checked={checked} value={title} onChange={onChange} onClick={onClick}/>
                <CardTile>
                    <CardImage>
                        <img src={image} alt={title} loading="lazy"/>
                    </CardImage>
                    <CardLabel>{name}</CardLabel>
                </CardTile>
            </Cardwrapper>
        </CardContainer>
    )
}

export default Card
