import React, { useState, useEffect } from 'react'
import { selectConvertNote, selectStrumString } from 'store/store';
import styled from 'styled-components';
import { useSelector } from 'react-redux'
import MIDISounds from "midi-sounds-react";
import _ from 'lodash'
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { Modal, Button, Form } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { StrumPlayer } from "components/player";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import { AiOutlineHome, AiOutlineArrowLeft } from "react-icons/ai";
import {
    getBlobFromImageElement,
    copyBlobToClipboard,
} from 'copy-image-clipboard';

const CardItemsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
    padding-bottom: 30px;
    padding-top: 50px;
    user-select: none;
    border: none;
    overflow-x: hidden;
    pointer-events: none;
    width: fit-content;
    height: fit-content;
    & > * {
        margin: 0.5rem 0.5rem;
    }
    svg { 
        display: none;
    }
    .container {
        margin: auto;
        width: 100%;
        max-width: 100%;
        
    }

`;
const CardItemsTitle = styled.legend`
    font-size: 1.5rem;
    font-weight: 700;
    color: #9c9c9c;
    text-align: center;
    line-height: 1.125;
    margin-bottom: 1.25rem;
`;

const TrackImage = styled.img`
  width: 100%;
  max-width: 100px;
  height: auto;

`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1.5rem 2.5rem;
    &>button{
        margin: 20px;
    }
`;

const ImageContainer = styled.div`
img {
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: pixelated;
    -ms-interpolation-mode: nearest-neighbor;
    max-width: 100%;
}
`;

let midiSounds: any;

const SortableItem = SortableElement(({ item }: any) => (
    <TrackImage src={item.src} alt="" />
));

const SortableList = SortableContainer(({ items }: any) => (
    <div className="container">
        {items.map((item: any, index: number) => (
            <SortableItem
                key={`${index}`}
                index={index}
                item={item}
            />
        ))}
    </div>
));

function ConvertImages() {

    const convertList = useSelector(selectConvertNote);
    const [playNotes, setPlayNotes] = useState<string[]>([])
    const [tracks, setTracks] = useState<any>([]);
    const [image, setImage] = useState();
    /* const [hauteur, setHauteur] = useState('');
    const [largeur, setLargeur] = useState(''); */
    const [resolution, setResolution] = useState('3');
    let navigate = useNavigate();
    const [hauteur, setHauteur] = useState(() => {
        // getting stored value
        const saved: any = localStorage.getItem("hauteur");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });

    const [largeur, setLargeur] = useState(() => {
        // getting stored value
        const saved: any = localStorage.getItem("largeur");
        const initialValue = JSON.parse(saved);
        return initialValue || "";
    });
    useEffect(() => {
        // Largeur & Hauteur
        localStorage.setItem("hauteur", JSON.stringify(hauteur));
        localStorage.setItem("largeur", JSON.stringify(largeur));
    }, [hauteur, largeur]);

    const strumString = useSelector(selectStrumString)
    const [show, setShow] = useState<boolean>();

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    useEffect(() => {
        _.map((convertList), (track) => {
            setTracks(track);
        })
    }, [convertList])

    useEffect(() => {
        tracks.forEach((note: any) => {
            setPlayNotes((prevState: any) => [...prevState, note.title])
        })
    }, [tracks])

    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        setTracks(arrayMoveImmutable(tracks, oldIndex, newIndex));
    };

    const handleConvert = () => {
        const element: any = document.getElementById('cardItems')
        html2canvas(element, {
            logging: false,
            scale: 16,
            scrollX: -window.scrollX,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight
        }).then((canvas: HTMLCanvasElement) => {
            const url: any = canvas.toDataURL();
            // downloadImage(url, "generate-notes.png");
            setImage(url)
        })

        setShow(true);
    }

    /*  const exportAsImage = async (el, imageFileName) => {
         const canvas = await html2canvas(element);
         const image = canvas.toDataURL("image/png", 1.0);
         downloadImage(image, imageFileName);
         }; */
    const downloadImage = (blob: any, fileName: any) => {

        const fakeLink: HTMLAnchorElement = document.createElement('a');
        fakeLink.setAttribute('style', 'display:none;');
        fakeLink.download = fileName;

        fakeLink.href = blob;

        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);

        fakeLink.remove();
    };


    const playTestInstrument = () => {
        const filtered = Array(strumString).filter((() => true));
        var player = new StrumPlayer(midiSounds, strumString.toString().replaceAll(',', ' '));
        player.play();


    }


    const handleHauteurChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setHauteur(value)
    }
    const handleLargeurChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setLargeur(value)
    }
    const handleResolutionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setResolution(value)
    }
    const handleClickConvert = () => {
        const element: any = document.getElementById('generatedImage')
        html2canvas(element, {
            logging: false, scale: 16,
            scrollX: -window.scrollX,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight
        }).then((canvas: HTMLCanvasElement) => {
            const url: any = canvas.toDataURL("image/png");

            // Adjust width and height
            /* var imgWidth = window.innerWidth;
            var imgHeight = window.innerHeight; */
            // jspdf changes
            const pdf = new jsPDF({
                orientation: 'p',
                unit: 'px',
                format: 'a4',
                hotfixes: ['px_scaling'],
            });
            var imgWidth = pdf.internal.pageSize.getWidth();
            var imgHeight = pdf.internal.pageSize.getHeight();


            pdf.addImage(url, "PNG", 15, 40, imgWidth, imgHeight); // 2: 19
            pdf.save(`Note-${('.pdf-title')}.pdf`);
        })
        setShow(false);
        /* setTimeout(() => {
            navigate('/');
        }, 5000); */
    }
    const handleClickCopy = () => {
        const element: any = document.getElementById('cardItems')
        html2canvas(element, {
            logging: false, scale: 16,
            scrollX: -window.scrollX,
            windowWidth: document.documentElement.offsetWidth,
            windowHeight: document.documentElement.offsetHeight
        }).then((canvas: HTMLCanvasElement) => {
            const url: any = canvas.toDataURL("image/png");
            setToClipboard(url);
        })
    }
    const handleClickSave = () => {
        const element: any = document.getElementById('generatedImage')
        html2canvas(element,
            {
                logging: false, scale: 16,
                scrollX: -window.scrollX,
                windowWidth: document.documentElement.offsetWidth,
                windowHeight: document.documentElement.offsetHeight,
            }).then((canvas: HTMLCanvasElement) => {
                const url: any = canvas.toDataURL();
                downloadImage(url, `generate-notes-${Math.floor(Math.random() * 100)}.png`);
            })
        setShow(false);
        /* setTimeout(() => {
            navigate('/');
        }, 5000); */
    }

    const setToClipboard = async (blob: any) => {
        const data = [new ClipboardItem({ [blob.type]: blob })]
        await navigator.clipboard.write(data)
    }

    const copyToClipboard = async (pngBlob: any) => {
        try {
            await navigator.clipboard.write([
                // eslint-disable-next-line no-undef
                new ClipboardItem({
                    [pngBlob.type]: pngBlob
                })
            ]);
        } catch (error) {
            console.error(error);
        }
    };

    const handleClickCopyTo = () => {
        /* const element: any = document.getElementById('generatedImage')
        html2canvas(element, { logging: false, scale: parseFloat(resolution) }).then((canvas: HTMLCanvasElement) => {
            const url: any = canvas.toDataURL('image/png');
            getBlobFromImageElement(element)
                .then((blob) => {
                    return copyBlobToClipboard(blob)
                })
                .then(() => {
                    console.log('Blob Copied')
                })
                .catch((e) => {
                    console.log('Error: ', e.message)
                })
        })
        setShow(false); */
        const element: any = document.getElementById('generatedImage')
        html2canvas(element,
            {
                logging: false, scale: 16,
                scrollX: -window.scrollX,
                windowWidth: document.documentElement.offsetWidth,
                windowHeight: document.documentElement.offsetHeight
            }).then((canvas: HTMLCanvasElement) => {
                const url: any = canvas.toDataURL();
                downloadImage(url, `generate-notes-${Math.floor(Math.random() * 100)}.jpeg`);
            })
        setShow(false);
        /* setTimeout(() => {
            navigate('/');
        }, 5000); */
    }
    return (
        <>
            <form>
                {/* <CardItemsTitle>Séctionner les notes</CardItemsTitle> */}
                <CardItemsContainer id="cardItems">
                    <SortableList items={tracks} /* onSortEnd={onSortEnd}  axis={"xy"} helperClass="SortableHelper" */ />
                </CardItemsContainer>
                {/* <ButtonContainer>
                    <Button variant="outline-primary" size="lg" onClick={playTestInstrument}>
                        Play
                    </Button>
                </ButtonContainer> */}

                <ButtonContainer>
                    <Button onClick={handleConvert} variant="outline-dark" size="lg">
                        EXPORTER IMAGE
                    </Button>
                </ButtonContainer>
                <ButtonContainer>

                    <Button variant="secondary" size="lg" onClick={() => navigate('/')}>
                        <AiOutlineHome size={30} />
                    </Button>
                    <Button onClick={() => navigate(-1)} variant="outline-dark" size="lg">
                        <AiOutlineArrowLeft size={30} />
                    </Button>

                </ButtonContainer>
                {/*  <MIDISounds
                    ref={(ref: any) => (midiSounds = ref)}
                    appElementName="root"
                    instruments={[6]}
                /> */}

            </form>
            {show && <ImageModal
                show={show}
                handleClose={handleClose}
                onChange={handleShow}
                image={image}
                hauteur={hauteur}
                largeur={largeur}
                resolution={resolution}
                handleHauteurChange={handleHauteurChange}
                handleLargeurChange={handleLargeurChange}
                handleResolutionChange={handleResolutionChange}
                handleClickConvert={handleClickConvert}
                handleClickCopy={handleClickCopy}
                handleClickSave={handleClickSave}
                handleClickCopyTo={handleClickCopyTo}
            />}
        </>
    )
}

interface EmptyModalProps {

    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    show?: boolean;
    image?: string;
    handleClose?: () => void;
    hauteur?: string;
    largeur?: string;
    resolution?: string;
    handleHauteurChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleLargeurChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleResolutionChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleClickConvert?: () => void;
    handleClickCopy?: () => void;
    handleClickSave?: () => void;
    handleClickCopyTo?: () => void;
    ref?: any;
}
const ImageModal = (props: EmptyModalProps) => {

    const {
        handleClose,
        show,
        image,
        hauteur,
        largeur,
        resolution,
        handleHauteurChange,
        handleLargeurChange,
        handleResolutionChange,
        handleClickConvert,
        handleClickCopy,
        handleClickSave,
        handleClickCopyTo,
    } = props;
    return (
        <Modal onHide={handleClose} show={show} size="xl">
            <Modal.Header closeButton>Choisir le format de sortie de l’image</Modal.Header>
            <Modal.Body>
                <div>
                    <div>
                        <Form.Label htmlFor="inputHauteur">Hauteur</Form.Label>
                        <Form.Control
                            name="hauteur"
                            value={hauteur}
                            onChange={handleHauteurChange}
                            id="inputHauteur"
                            aria-describedby="passwordHelpBlock"
                        />
                        <Form.Label htmlFor="inputPassword5">Largeur</Form.Label>
                        <Form.Control
                            name="largeur"
                            value={largeur}
                            onChange={handleLargeurChange}
                            id="inputLargeur"
                            aria-describedby="passwordHelpBlock"
                        />
                        <Form.Label htmlFor="inputPassword5">Résolution</Form.Label>
                        <Form.Control
                            name="number"
                            value={resolution}
                            onChange={handleResolutionChange}
                            id="inputRésolution"
                            aria-describedby="passwordHelpBlock"
                        />
                    </div>
                    <ImageContainer>
                        <img id="generatedImage" src={image} width={`${largeur}px`} height={`${hauteur}px`} alt="" />
                    </ImageContainer>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleClickConvert}>
                    CONVERTIR EN PDF
                </Button>
                <Button variant="outline-secondary" onClick={handleClickCopy}>
                    COPIER L’IMAGE dans drive
                </Button>
                <Button variant="outline-success" onClick={handleClickSave}>
                    ENREGISTRER SOUS
                </Button>
                <Button variant="outline-dark" onClick={handleClickCopyTo}>
                    CONVERTIR EN JPEG
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ConvertImages
