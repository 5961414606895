import { INote } from "interfaces/note";

const melodyNotes: INote[] = [
  /* Noire */
  {
    id: 1,
    title: "Clé SOL",
    name: "Clé SOL",
    type: "Noire",
    image: "/assets/images/Clef-SOL.jpg",
    file: "/assets/audios/SOL.mp3"
  },
  {
    id: 2,
    title: "Clé FA",
    name: "Clé de FA",
    type: "Noire",
    image: "/assets/images/Clef-FA.jpg",
    file: "/assets/audios/FA.mp3"
  },
  {
    id: 3,
    title: "Clé UT3",
    name: "Clé UT3",
    type: "Noire",
    image: "/assets/images/Clef-UT3.jpg",
    file: "/assets/audios/UT3.mp3"
  },
  {
    id: 4,
    title: "Clé UT4",
    name: "Clé UT4",
    type: "Noire",
    image: "/assets/images/Clef-UT4.jpg",
    file: "/assets/images/UT4.jpg"
  },
  {
    id: 5,
    title: "barre de mesure",
    name: "barre de mesure",
    type: "Noire",
    image: "/assets/images/Barre-mesure.jpg",
    file: "/assets/images/barre-mesure.jpg"
  },
  {
    id: 6,
    title: "barre de fin",
    name: "barre de fin",
    type: "Noire",
    image: "/assets/images/Double-barre-fin.jpg",
    file: "/assets/images/barre-fin.jpg"
  },
  {
    id: 7,
    title: "G2",
    name: "G2",
    type: "Noire",
    image: "/assets/images/G2.jpg",
    file: "/assets/audios/SOL2.mp3"
  },
  {
    id: 8,
    title: "A2",
    name: "A2",
    type: "Noire",
    image: "/assets/images/A2.jpg",
    file: "/assets/audios/LA2.mp3"
  },
  {
    id: 9,
    title: "B2",
    name: "B2",
    type: "Noire",
    image: "/assets/images/B2.jpg",
    file: "/assets/audios/SI2.mp3"
  },
  {
    id: 10,
    title: "C3",
    name: "C3",
    type: "Noire",
    image: "/assets/images/C3.jpg",
    file: "/assets/audios/DO3.mp3"
  },
  {
    id: 11,
    title: "D3",
    name: "D3",
    type: "Noire",
    image: "/assets/images/D3.jpg",
    file: "/assets/audios/RE3.mp3"
  },
  {
    id: 12,
    title: "E3",
    name: "E3",
    type: "Noire",
    image: "/assets/images/E3.jpg",
    file: "/assets/audios/MI3.mp3"
  },
  {
    id: 13,
    title: "F3",
    name: "F3",
    type: "Noire",
    image: "/assets/images/F3.jpg",
    file: "/assets/audios/FA3.mp3"
  },
  {
    id: 14,
    title: "G3",
    name: "G3",
    type: "Noire",
    image: "/assets/images/G3.jpg",
    file: "/assets/audios/SOL3.mp3"
  },
  {
    id: 15,
    title: "A3",
    name: "A3",
    type: "Noire",
    image: "/assets/images/A3.jpg",
    file: "/assets/audios/LA3.mp3"
  },
  {
    id: 16,
    title: "B3",
    name: "B3",
    type: "Noire",
    image: "/assets/images/B3.jpg",
    file: "/assets/audios/SI3.mp3"
  },
  {
    id: 17,
    title: "C4",
    name: "C4",
    type: "Noire",
    image: "/assets/images/C4.jpg",
    file: "/assets/audios/DO4.mp3"
  },
  {
    id: 18,
    title: "D4",
    name: "D4",
    type: "Noire",
    image: "/assets/images/D4.jpg",
    file: "/assets/audios/RE4.mp3"
  },
  {
    id: 19,
    title: "E4",
    name: "E4",
    type: "Noire",
    image: "/assets/images/E4.jpg",
    file: "/assets/audios/MI4.mp3"
  },
  {
    id: 20,
    title: "F4",
    name: "F4",
    type: "Noire",
    image: "/assets/images/F4.jpg",
    file: "/assets/audios/FA4.mp3"
  },
  {
    id: 21,
    title: "G4",
    name: "G4",
    type: "Noire",
    image: "/assets/images/G4.jpg",
    file: "/assets/audios/SOL4.mp3"
  },
  {
    id: 22,
    title: "A4",
    name: "A4",
    type: "Noire",
    image: "/assets/images/A4.jpg",
    file: "/assets/audios/LA4.mp3"
  },
  {
    id: 23,
    title: "B4",
    name: "B4",
    type: "Noire",
    image: "/assets/images/B4.jpg",
    file: "/assets/audios/SI4.mp3"
  },
  {
    id: 24,
    title: "C5",
    name: "C5",
    type: "Noire",
    image: "/assets/images/C5.jpg",
    file: "/assets/audios/SI4.mp3"
  },
  {
    id: 25,
    title: "D5",
    name: "D5",
    type: "Noire",
    image: "/assets/images/D5.jpg",
    file: "/assets/audios/SI4.mp3"
  },
  {
    id: 26,
    title: "Gb2",
    name: "Gb2",
    type: "Noire",
    image: "/assets/images/Gb2.jpg",
    file: "/assets/audios/SOLd2.mp3"
  },
  {
    id: 27,
    title: "Ab2",
    name: "Ab2",
    type: "Noire",
    image: "/assets/images/Ab2.jpg",
    file: "/assets/images/Ab2.jpg"
  },
  {
    id: 28,
    title: "Bb2",
    name: "Bb2",
    type: "Noire",
    image: "/assets/images/Bb2.jpg",
    file: "/assets/audios/SId2.mp3"
  },
  {
    id: 29,
    title: "Cb3",
    name: "Cb3",
    type: "Noire",
    image: "/assets/images/Cb3.jpg",
    file: "/assets/audios/DOd3.mp3"
  },
  {
    id: 30,
    title: "Db3",
    name: "Db3",
    type: "Noire",
    image: "/assets/images/Db3.jpg",
    file: "/assets/audios/REd3.mp3"
  },
  {
    id: 31,
    title: "Eb3",
    name: "Eb3",
    type: "Noire",
    image: "/assets/images/Eb3.jpg",
    file: "/assets/audios/MId3.mp3"
  },
  {
    id: 31,
    title: "Fb3",
    name: "Fb3",
    type: "Noire",
    image: "/assets/images/Fb3.jpg",
    file: "/assets/audios/FAd3.mp3"
  },
  {
    id: 32,
    title: "Gb3",
    name: "Gb3",
    type: "Noire",
    image: "/assets/images/Gb3.jpg",
    file: "/assets/audios/SOLd3.mp3"
  },
  {
    id: 33,
    title: "Ab3",
    name: "Ab3",
    type: "Noire",
    image: "/assets/images/Ab3.jpg",
    file: "/assets/audios/noire-lab3.mp3"
  },
  {
    id: 34,
    title: "Bb3",
    name: "Bb3",
    type: "Noire",
    image: "/assets/images/Bb3.jpg",
    file: "/assets/audios/SId3.mp3"
  },
  {
    id: 35,
    title: "Cb4",
    name: "Cb4",
    type: "Noire",
    image: "/assets/images/Cb4.jpg",
    file: "/assets/audios/DOd4.mp3"
  },
  {
    id: 36,
    title: "Db4",
    name: "Db4",
    type: "Noire",
    image: "/assets/images/Db4.jpg",
    file: "/assets/audios/REd4.mp3"
  },
  {
    id: 37,
    title: "Eb4",
    name: "Eb4",
    type: "Noire",
    image: "/assets/images/Eb4.jpg",
    file: "/assets/audios/D3.mp3"
  },
  {
    id: 38,
    title: "Fb4",
    name: "Fb4",
    type: "Noire",
    image: "/assets/images/Fb4.jpg",
    file: "/assets/audios/FAd4.mp3"
  },
  {
    id: 39,
    title: "Gb4",
    name: "Gb4",
    type: "Noire",
    image: "/assets/images/Gb4.jpg",
    file: "/assets/audios/SOLd4.mp3"
  },
  {
    id: 40,
    title: "Ab4",
    name: "Ab4",
    type: "Noire",
    image: "/assets/images/Ab4.jpg",
    file: "/assets/audios/noire-lab4.mp3"
  },
  {
    id: 41,
    title: "Bb4",
    name: "Bb4",
    type: "Noire",
    image: "/assets/images/Bb4.jpg",
    file: "/assets/audios/SId4.mp3"
  },
  {
    id: 42,
    title: "Cb5",
    name: "Cb5",
    type: "Noire",
    image: "/assets/images/Cb5.jpg",
    file: "/assets/audios/SId4.mp3"
  },
  {
    id: 43,
    title: "Db5",
    name: "Db5",
    type: "Noire",
    image: "/assets/images/Db5.jpg",
    file: "/assets/audios/SId4.mp3"
  },
  {
    id: 44,
    title: "G#2",
    name: "G#2",
    type: "Noire",
    image: "/assets/images/GHash2.jpg",
    file: "/assets/audios/SOLDi2.mp3"
  },
  {
    id: 45,
    title: "A#2",
    name: "A#2",
    type: "Noire",
    image: "/assets/images/AHash2.jpg",
    file: "/assets/audios/LADi2.mp3"
  },
  {
    id: 46,
    title: "B#2",
    name: "B#2",
    type: "Noire",
    image: "/assets/images/BHash2.jpg",
    file: "/assets/audios/SIDi2.mp3"
  },
  {
    id: 47,
    title: "C#3",
    name: "C#3",
    type: "Noire",
    image: "/assets/images/CHash3.jpg",
    file: "/assets/audios/DODi3.mp3"
  },
  {
    id: 48,
    title: "D#3",
    name: "D#3",
    type: "Noire",
    image: "/assets/images/DHash3.jpg",
    file: "/assets/audios/REDi3.mp3"
  },
  {
    id: 49,
    title: "E#3",
    name: "E#3",
    type: "Noire",
    image: "/assets/images/EHash3.jpg",
    file: "/assets/audios/MIDi3.mp3"
  },
  {
    id: 50,
    title: "F#3",
    name: "F#3",
    type: "Noire",
    image: "/assets/images/FHash3.jpg",
    file: "/assets/audios/FADi3.mp3"
  },
  {
    id: 51,
    title: "G#3",
    name: "G#3",
    type: "Noire",
    image: "/assets/images/GHash3.jpg",
    file: "/assets/audios/SOLDi3.mp3"
  },
  {
    id: 52,
    title: "A#3",
    name: "A#3",
    type: "Noire",
    image: "/assets/images/AHash3.jpg",
    file: "/assets/audios/LADi3.mp3"
  },
  {
    id: 53,
    title: "B#3",
    name: "B#3",
    type: "Noire",
    image: "/assets/images/BHash3.jpg",
    file: "/assets/audios/SIDi3.mp3"
  },
  {
    id: 54,
    title: "C#4",
    name: "C#4",
    type: "Noire",
    image: "/assets/images/CHash4.jpg",
    file: "/assets/audios/DODi4.mp3"
  },
  {
    id: 55,
    title: "D#4",
    name: "D#4",
    type: "Noire",
    image: "/assets/images/DHash4.jpg",
    file: "/assets/audios/REDi4.mp3"
  },
  {
    id: 56,
    title: "E#4",
    name: "E#4",
    type: "Noire",
    image: "/assets/images/EHash4.jpg",
    file: "/assets/audios/MIDi4.mp3"
  },
  {
    id: 57,
    title: "F#4",
    name: "F#4",
    type: "Noire",
    image: "/assets/images/FHash4.jpg",
    file: "/assets/audios/FADi4.mp3"
  },
  {
    id: 58,
    title: "G#4",
    name: "G#4",
    type: "Noire",
    image: "/assets/images/GHash4.jpg",
    file: "/assets/audios/SOLDi4.mp3"
  },
  {
    id: 59,
    title: "A#4",
    name: "A#4",
    type: "Noire",
    image: "/assets/images/AHash4.jpg",
    file: "/assets/audios/LADi4.mp3"
  },
  {
    id: 60,
    title: "B#4",
    name: "B#4",
    type: "Noire",
    image: "/assets/images/BHash4.jpg",
    file: "/assets/audios/SIDi4.mp3"
  },
  {
    id: 61,
    title: "C#5",
    name: "C#5",
    type: "Noire",
    image: "/assets/images/CHash5.jpg",
    file: "/assets/audios/SIDi4.mp3"
  },
  {
    id: 62,
    title: "D#5",
    name: "D#5",
    type: "Noire",
    image: "/assets/images/DHash5.jpg",
    file: "/assets/audios/SIDi4.mp3"
  },
  {
    id: 63,
    title: "Pause",
    name: "Pause",
    type: "Noire",
    image: "/assets/images/pause.jpg",
    file: "/assets/images/pause.jpg"
  },
  {
    id: 64,
    title: "1/2 pause",
    name: "1/2 pause",
    type: "Noire",
    image: "/assets/images/1_2_pause.jpg",
    file: "/assets/images/1-sur-2-pause.jpg"
  },
  {
    id: 65,
    title: "Soupir",
    name: "Soupir",
    type: "Noire",
    image: "/assets/images/Soupir.jpg",
    file: "/assets/images/soupir.jpg"
  },
  {
    id: 66,
    title: "1/2 soupir pointé",
    name: "1/2 soupir pointé",
    type: "Noire",
    image: "/assets/images/1_2_soupir_pointe.jpg",
    file: "/assets/images/soupire-pointee.jpg"
  },
  {
    id: 67,
    title: "1/2 soupir",
    name: "1/2 soupir",
    type: "Noire",
    image: "/assets/images/1_2_soupir.jpg",
    file: "/assets/images/1-2-soupir.jpg"
  },
  {
    id: 68,
    title: "1/4 de soupir",
    name: "1/4 de soupir",
    type: "Noire",
    image: "/assets/images/1_4_soupir.jpg",
    file: "/assets/images/1-4-soupir.jpg"
  },

  /* Croche */
  {
      id: 64,
      title: "G2",
      name: "G2",
      type: "Croche",
      image: "/assets/images/croche/G2.jpg",
      file: "/assets/audios/SOL2croche.mp3"
    },
    {
      id: 65,
      title: "A2",
      name: "A2",
      type: "Croche",
      image: "/assets/images/croche/A2.jpg",
      file: "/assets/audios/LA2croche.mp3"
    },
    {
      id: 66,
      title: "B2",
      name: "B2",
      type: "Croche",
      image: "/assets/images/croche/B2.jpg",
      file: "/assets/audios/SI2croche.mp3"
    },
    {
      id: 67,
      title: "C3",
      name: "C3",
      type: "Croche",
      image: "/assets/images/croche/C3.jpg",
      file: "/assets/audios/DO3croche.mp3"
    },
    {
      id: 68,
      title: "D3",
      name: "D3",
      type: "Croche",
      image: "/assets/images/croche/D3.jpg",
      file: "/assets/audios/RE3croche.mp3"
    },
    {
      id: 69,
      title: "E3",
      name: "E3",
      type: "Croche",
      image: "/assets/images/croche/E3.jpg",
      file: "/assets/audios/MI3croche.mp3"
    },
    {
      id: 70,
      title: "F3",
      name: "F3",
      type: "Croche",
      image: "/assets/images/croche/F3.jpg",
      file: "/assets/audios/FA3croche.mp3"
    },
    {
      id: 71,
      title: "G3",
      name: "G3",
      type: "Croche",
      image: "/assets/images/croche/G3.jpg",
      file: "/assets/audios/SOL3croche.mp3"
    },
    {
      id: 72,
      title: "A3",
      name: "A3",
      type: "Croche",
      image: "/assets/images/croche/A3.jpg",
      file: "/assets/audios/LA3croche.mp3"
    },
    {
      id: 73,
      title: "B3",
      name: "B3",
      type: "Croche",
      image: "/assets/images/croche/B3.jpg",
      file: "/assets/audios/SI3croche.mp3"
    },
    {
      id: 74,
      title: "C4",
      name: "C4",
      type: "Croche",
      image: "/assets/images/croche/C4.jpg",
      file: "/assets/audios/DO4croche.mp3"
    },
    {
      id: 75,
      title: "D4",
      name: "D4",
      type: "Croche",
      image: "/assets/images/croche/D4.jpg",
      file: "/assets/audios/RE4croche.mp3"
    },
    {
      id: 76,
      title: "E4",
      name: "E4",
      type: "Croche",
      image: "/assets/images/croche/E4.jpg",
      file: "/assets/audios/MI4croche.mp3"
    },
    {
      id: 77,
      title: "F4",
      name: "F4",
      type: "Croche",
      image: "/assets/images/croche/F4.jpg",
      file: "/assets/audios/FA4croche.mp3"
    },
    {
      id: 78,
      title: "G4",
      name: "G4",
      type: "Croche",
      image: "/assets/images/croche/G4.jpg",
      file: "/assets/audios/SOLDSOL4crochei4.mp3"
    },
    {
      id: 79,
      title: "A4",
      name: "A4",
      type: "Croche",
      image: "/assets/images/croche/A4.jpg",
      file: "/assets/audios/LA4croche.mp3"
    },
    {
      id: 80,
      title: "B4",
      name: "B4",
      type: "Croche",
      image: "/assets/images/croche/B4.jpg",
      file: "/assets/audios/SI4croche.mp3"
    },
    {
      id: 81,
      title: "Gb2",
      name: "Gb2",
      type: "Croche",
      image: "/assets/images/croche/Gb2.jpg",
      file: "/assets/audios/SOLb2croche.mp3"
    },
    {
      id: 82,
      title: "Ab2",
      name: "Ab2",
      type: "Croche",
      image: "/assets/images/croche/Ab2.jpg",
      file: "/assets/audios/LAb2croche.mp3"
    },
    {
      id: 83,
      title: "Bb2",
      name: "Bb2",
      type: "Croche",
      image: "/assets/images/croche/Bb2.jpg",
      file: "/assets/audios/SIb2croche.mp3"
    },
    {
      id: 84,
      title: "Cb3",
      name: "Cb3",
      type: "Croche",
      image: "/assets/images/croche/Cb3.jpg",
      file: "/assets/audios/DOb3croche.mp3"
    },
    {
      id: 85,
      title: "Db3",
      name: "Db3",
      type: "Croche",
      image: "/assets/images/croche/Db3.jpg",
      file: "/assets/audios/REb3croche.mp3"
    },
    {
      id: 86,
      title: "Eb3",
      name: "Eb3",
      type: "Croche",
      image: "/assets/images/croche/Eb3.jpg",
      file: "/assets/audios/MIb3croche.mp3"
    },
    {
      id: 87,
      title: "Fb3",
      name: "Fb3",
      type: "Croche",
      image: "/assets/images/croche/Fb3.jpg",
      file: "/assets/audios/FAb3croche.mp3"
    },
    {
      id: 88,
      title: "Gb3",
      name: "Gb3",
      type: "Croche",
      image: "/assets/images/croche/Gb3.jpg",
      file: "/assets/audios/SOLb3croche.mp3"
    },
    {
      id: 89,
      title: "Ab3",
      name: "Ab3",
      type: "Croche",
      image: "/assets/images/croche/Ab3.jpg",
      file: "/assets/audios/LAb3croche.mp3"
    },
    {
      id: 90,
      title: "Bb3",
      name: "Bb3",
      type: "Croche",
      image: "/assets/images/croche/Bb3.jpg",
      file: "/assets/audios/SIb3croche.mp3"
    },
    {
      id: 91,
      title: "Cb4",
      name: "Cb4",
      type: "Croche",
      image: "/assets/images/croche/Cb4.jpg",
      file: "/assets/audios/DOb4croche.mp3"
    },
    {
      id: 92,
      title: "Db4",
      name: "Db4",
      type: "Croche",
      image: "/assets/images/croche/Db4.jpg",
      file: "/assets/audios/REb4croche.mp3"
    },
    {
      id: 93,
      title: "Eb4",
      name: "Eb4",
      type: "Croche",
      image: "/assets/images/croche/Eb4.jpg",
      file: "/assets/audios/MIb4croche.mp3"
    },
    {
      id: 94,
      title: "Fb4",
      name: "Fb4",
      type: "Croche",
      image: "/assets/images/croche/Fb4.jpg",
      file: "/assets/audios/FAb4croche.mp3"
    },
    {
      id: 95,
      title: "Gb4",
      name: "Gb4",
      type: "Croche",
      image: "/assets/images/croche/Gb4.jpg",
      file: "/assets/audios/SOLb4croche.mp3"
    },
    {
      id: 96,
      title: "Ab4",
      name: "Ab4",
      type: "Croche",
      image: "/assets/images/croche/Ab4.jpg",
      file: "/assets/audios/LAb4croche.mp3"
    },
    {
      id: 97,
      title: "Bb4",
      name: "Bb4",
      type: "Croche",
      image: "/assets/images/croche/Bb4.jpg",
      file: "/assets/audios/SIb4croche.mp3"
    },
    {
      id: 98,
      title: "G#2",
      name: "G#2",
      type: "Croche",
      image: "/assets/images/croche/GHash2.jpg",
      file: "/assets/audios/SOLd2croche .mp3"
    },
    {
      id: 99,
      title: "A#2",
      name: "A#2",
      type: "Croche",
      image: "/assets/images/croche/AHash2.jpg",
      file: "/assets/audios/LAd2croche.mp3"
    },
    {
      id: 100,
      title: "B#2",
      name: "B#2",
      type: "Croche",
      image: "/assets/images/croche/BHash2.jpg",
      file: "/assets/audios/SId2croche.mp3"
    },
    {
      id: 101,
      title: "C#3",
      name: "C#3",
      type: "Croche",
      image: "/assets/images/croche/CHash3.jpg",
      file: "/assets/audios/DOd3croche.mp3"
    },
    {
      id: 102,
      title: "D#3",
      name: "D#3",
      type: "Croche",
      image: "/assets/images/croche/DHash3.jpg",
      file: "/assets/audios/REd3croche.mp3"
    },
    {
      id: 103,
      title: "E#3",
      name: "E#3",
      type: "Croche",
      image: "/assets/images/croche/EHash3.jpg",
      file: "/assets/audios/MId3croche.mp3"
    },
    {
      id: 104,
      title: "F#3",
      name: "F#3",
      type: "Croche",
      image: "/assets/images/croche/FHash3.jpg",
      file: "/assets/audios/FAd3croche.mp3"
    },
    {
      id: 105,
      title: "G#3",
      name: "G#3",
      type: "Croche",
      image: "/assets/images/croche/GHash3.jpg",
      file: "/assets/audios/SOLd3croche.mp3"
    },
    {
      id: 106,
      title: "A#3",
      name: "A#3",
      type: "Croche",
      image: "/assets/images/croche/AHash3.jpg",
      file: "/assets/audios/LAd3croche.mp3"
    },
    {
      id: 107,
      title: "B#3",
      name: "B#3",
      type: "Croche",
      image: "/assets/images/croche/BHash3.jpg",
      file: "/assets/audios/SId3croche.mp3"
    },
    {
      id: 108,
      title: "C#4",
      name: "C#4",
      type: "Croche",
      image: "/assets/images/croche/CHash4.jpg",
      file: "/assets/audios/DOd4croche.mp3"
    },
    {
      id: 109,
      title: "D#4",
      name: "D#4",
      type: "Croche",
      image: "/assets/images/croche/DHash4.jpg",
      file: "/assets/audios/REd4croche.mp3"
    },
    {
      id: 110,
      title: "E#4",
      name: "E#4",
      type: "Croche",
      image: "/assets/images/croche/EHash4.jpg",
      file: "/assets/audios/MId4croche.mp3"
    },
    {
      id: 111,
      title: "F#4",
      name: "F#4",
      type: "Croche",
      image: "/assets/images/croche/FHash4.jpg",
      file: "/assets/audios/FAd4croche.mp3"
    },
    {
      id: 112,
      title: "G#4",
      name: "G#4",
      type: "Croche",
      image: "/assets/images/croche/GHash4.jpg",
      file: "/assets/audios/SOLd4croche.mp3"
    },
    {
      id: 113,
      title: "A#4",
      name: "A#4",
      type: "Croche",
      image: "/assets/images/croche/AHash4.jpg",
      file: "/assets/audios/LAd4croche.mp3"
    },
    {
      id: 114,
      title: "B#4",
      name: "B#4",
      type: "Croche",
      image: "/assets/images/croche/BHash4.jpg",
      file: "/assets/audios/SId4croche.mp3"
    },
  /* Croche Pointée */
  {
      id: 115,
      title: "G2",
      name: "G2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/G2.jpg",
      file: "/assets/audios/G2croche-pointée.mp3"
    },
    {
      id: 116,
      title: "A2",
      name: "A2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/A2.jpg",
      file: "/assets/audios/A2croche-pointée.mp3"
    },
    {
      id: 117,
      title: "B2",
      name: "B2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/B2.jpg",
      file: "/assets/audios/B2croche-pointée.mp3"
    },
    {
      id: 118,
      title: "C3",
      name: "C3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/C3.jpg",
      file: "/assets/audios/C3croche-pointée.mp3"
    },
    {
      id: 119,
      title: "D3",
      name: "D3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/D3.jpg",
      file: "/assets/audios/D3croche-pointée.mp3"
    },
    {
      id: 120,
      title: "E3",
      name: "E3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/E3.jpg",
      file: "/assets/audios/E3croche-pointée.mp3"
    },
    {
      id: 121,
      title: "F3",
      name: "F3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/F3.jpg",
      file: "/assets/audios/F3croche-pointée.mp3"
    },
    {
      id: 122,
      title: "G3",
      name: "G3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/G3.jpg",
      file: "/assets/audios/G3croche-pointée.mp3"
    },
    {
      id: 123,
      title: "A3",
      name: "A3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/A3.jpg",
      file: "/assets/audios/A3croche-pointée.mp3"
    },
    {
      id: 124,
      title: "B3",
      name: "B3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/B3.jpg",
      file: "/assets/audios/B3croche-pointée.mp3"
    },
    {
      id: 125,
      title: "C4",
      name: "C4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/C4.jpg",
      file: "/assets/audios/C4croche-pointée.mp3"
    },
    {
      id: 126,
      title: "D4",
      name: "D4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/D4.jpg",
      file: "/assets/audios/D4croche-pointée.mp3"
    },
    {
      id: 127,
      title: "E4",
      name: "E4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/E4.jpg",
      file: "/assets/audios/E4croche-pointée.mp3"
    },
    {
      id: 128,
      title: "F4",
      name: "F4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/F4.jpg",
      file: "/assets/audios/F4croche-pointée.mp3"
    },
    {
      id: 129,
      title: "G4",
      name: "G4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/G4.jpg",
      file: "/assets/audios/G4croche-pointée.mp3"
    },
    {
      id: 130,
      title: "A4",
      name: "A4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/A4.jpg",
      file: "/assets/audios/A4croche-pointée.mp3"
    },
    {
      id: 131,
      title: "B4",
      name: "B4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/B4.jpg",
      file: "/assets/audios/B4croche-pointée.mp3"
    },
    {
      id: 132,
      title: "Gb2",
      name: "Gb2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Gb2.jpg",
      file: "/assets/audios/G2bcroche-pointée.mp3"
    },
    {
      id: 133,
      title: "Ab2",
      name: "Ab2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Ab2.jpg",
      file: "/assets/audios/A2bcroche-pointée.mp3"
    },
    {
      id: 134,
      title: "Bb2",
      name: "Bb2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Bb2.jpg",
      file: "/assets/audios/B2bcroche-pointée.mp3"
    },
    {
      id: 135,
      title: "Cb3",
      name: "Cb3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Cb3.jpg",
      file: "/assets/audios/C3bcroche-pointée.mp3"
    },
    {
      id: 136,
      title: "Db3",
      name: "Db3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Db3.jpg",
      file: "/assets/audios/D3bcroche-pointée.mp3"
    },
    {
      id: 137,
      title: "Eb3",
      name: "Eb3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Eb3.jpg",
      file: "/assets/audios/E3bcroche-pointée.mp3"
    },
    {
      id: 138,
      title: "Fb3",
      name: "Fb3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Fb3.jpg",
      file: "/assets/audios/F3bcroche-pointée.mp3"
    },
    {
      id: 139,
      title: "Gb3",
      name: "Gb3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Gb3.jpg",
      file: "/assets/audios/G3bcroche-pointée.mp3"
    },
    {
      id: 140,
      title: "Ab3",
      name: "Ab3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Ab3.jpg",
      file: "/assets/audios/A3bcroche-pointée.mp3"
    },
    {
      id: 141,
      title: "Bb3",
      name: "Bb3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Bb3.jpg",
      file: "/assets/audios/B3bcroche-pointée.mp3"
    },
    {
      id: 142,
      title: "Cb4",
      name: "Cb4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Cb4.jpg",
      file: "/assets/audios/C4bcroche-pointée.mp3"
    },
    {
      id: 143,
      title: "Db4",
      name: "Db4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Db4.jpg",
      file: "/assets/audios/D4bcroche-pointée.mp3"
    },
    {
      id: 144,
      title: "Eb4",
      name: "Eb4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Eb4.jpg",
      file: "/assets/audios/E4bcroche-pointée.mp3"
    },
    {
      id: 145,
      title: "Fb4",
      name: "Fb4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Fb4.jpg",
      file: "/assets/audios/F4bcroche-pointée.mp3"
    },
    {
      id: 146,
      title: "Gb4",
      name: "Gb4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Gb4.jpg",
      file: "/assets/audios/G4bcroche-pointée.mp3"
    },
    {
      id: 147,
      title: "Ab4",
      name: "Ab4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Ab4.jpg",
      file: "/assets/audios/A4bcroche-pointée.mp3"
    },
    {
      id: 148,
      title: "Bb4",
      name: "Bb4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/Bb4.jpg",
      file: "/assets/audios/B4bcroche-pointée.mp3"
    },
    {
      id: 149,
      title: "G#2",
      name: "G#2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/GHash2.jpg",
      file: "/assets/audios/G2dcroche-pointée.mp3"
    },
    {
      id: 150,
      title: "A#2",
      name: "A#2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/AHash2.jpg",
      file: "/assets/audios/A2dcroche-pointée.mp3"
    },
    {
      id: 151,
      title: "B#2",
      name: "B#2",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/BHash2.jpg",
      file: "/assets/audios/B2dcroche-pointée.mp3"
    },
    {
      id: 152,
      title: "C#3",
      name: "C#3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/CHash3.jpg",
      file: "/assets/audios/C3dcroche-pointée.mp3"
    },
    {
      id: 153,
      title: "D#3",
      name: "D#3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/DHash3.jpg",
      file: "/assets/audios/D3dcroche-pointée.mp3"
    },
    {
      id: 154,
      title: "E#3",
      name: "E#3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/EHash3.jpg",
      file: "/assets/audios/E3dcroche-pointée.mp3"
    },
    {
      id: 155,
      title: "F#3",
      name: "F#3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/FHash3.jpg",
      file: "/assets/audios/F3dcroche-pointée.mp3"
    },
    {
      id: 156,
      title: "G#3",
      name: "G#3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/GHash3.jpg",
      file: "/assets/audios/G3dcroche-pointée.mp3"
    },
    {
      id: 157,
      title: "A#3",
      name: "A#3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/AHash3.jpg",
      file: "/assets/audios/A3dcroche-pointée.mp3"
    },
    {
      id: 158,
      title: "B#3",
      name: "B#3",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/BHash3.jpg",
      file: "/assets/audios/B3dcroche-pointée.mp3"
    },
    {
      id: 159,
      title: "C#4",
      name: "C#4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/CHash4.jpg",
      file: "/assets/audios/C4dcroche-pointée.mp3"
    },
    {
      id: 160,
      title: "D#4",
      name: "D#4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/DHash4.jpg",
      file: "/assets/audios/D4dcroche-pointée.mp3"
    },
    {
      id: 161,
      title: "E#4",
      name: "E#4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/EHash4.jpg",
      file: "/assets/audios/E4dcroche-pointée.mp3"
    },
    {
      id: 162,
      title: "F#4",
      name: "F#4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/FHash4.jpg",
      file: "/assets/audios/F4dcroche-pointée.mp3"
    },
    {
      id: 163,
      title: "G#4",
      name: "G#4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/GHash4.jpg",
      file: "/assets/audios/G4dcroche-pointée.mp3"
    },
    {
      id: 164,
      title: "A#4",
      name: "A#4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/AHash4.jpg",
      file: "/assets/audios/A4dcroche-pointée.mp3"
    },
    {
      id: 165,
      title: "B#4",
      name: "B#4",
      type: "Croche Pointée",
      image: "/assets/images/croche_pointee/BHash4.jpg",
      file: "/assets/audios/B4dcroche-pointée.mp3"
    },
  /* Blanche */
  {
    id: 166,
    title: "G2",
    name: "G2",
    type: "Blanche",
    image: "/assets/images/blanche/G2.jpg",
    file: "/assets/audios/SOL2croche.mp3"
  },
  {
    id: 167,
    title: "A2",
    name: "A2",
    type: "Blanche",
    image: "/assets/images/blanche/A2.jpg",
    file: "/assets/audios/LA2croche.mp3"
  },
  {
    id: 168,
    title: "B2",
    name: "B2",
    type: "Blanche",
    image: "/assets/images/blanche/B2.jpg",
    file: "/assets/audios/SI2croche.mp3"
  },
  {
    id: 169,
    title: "C3",
    name: "C3",
    type: "Blanche",
    image: "/assets/images/blanche/C3.jpg",
    file: "/assets/audios/DO3croche.mp3"
  },
  {
    id: 170,
    title: "D3",
    name: "D3",
    type: "Blanche",
    image: "/assets/images/blanche/D3.jpg",
    file: "/assets/audios/RE3croche.mp3"
  },
  {
    id: 171,
    title: "E3",
    name: "E3",
    type: "Blanche",
    image: "/assets/images/blanche/E3.jpg",
    file: "/assets/audios/MI3croche.mp3"
  },
  {
    id: 172,
    title: "F3",
    name: "F3",
    type: "Blanche",
    image: "/assets/images/blanche/F3.jpg",
    file: "/assets/audios/FA3croche.mp3"
  },
  {
    id: 173,
    title: "G3",
    name: "G3",
    type: "Blanche",
    image: "/assets/images/blanche/G3.jpg",
    file: "/assets/audios/SOL3croche.mp3"
  },
  {
    id: 174,
    title: "A3",
    name: "A3",
    type: "Blanche",
    image: "/assets/images/blanche/A3.jpg",
    file: "/assets/audios/LA3croche.mp3"
  },
  {
    id: 175,
    title: "B3",
    name: "B3",
    type: "Blanche",
    image: "/assets/images/blanche/B3.jpg",
    file: "/assets/audios/SI3croche.mp3"
  },
  {
    id: 176,
    title: "C4",
    name: "C4",
    type: "Blanche",
    image: "/assets/images/blanche/C4.jpg",
    file: "/assets/audios/DO4croche.mp3"
  },
  {
    id: 177,
    title: "D4",
    name: "D4",
    type: "Blanche",
    image: "/assets/images/blanche/D4.jpg",
    file: "/assets/audios/RE4croche.mp3"
  },
  {
    id: 178,
    title: "E4",
    name: "E4",
    type: "Blanche",
    image: "/assets/images/blanche/E4.jpg",
    file: "/assets/audios/MI4croche.mp3"
  },
  {
    id: 179,
    title: "F4",
    name: "F4",
    type: "Blanche",
    image: "/assets/images/blanche/F4.jpg",
    file: "/assets/audios/FA4croche.mp3"
  },
  {
    id: 180,
    title: "G4",
    name: "G4",
    type: "Blanche",
    image: "/assets/images/blanche/G4.jpg",
    file: "/assets/audios/SOLDSOL4crochei4.mp3"
  },
  {
    id: 181,
    title: "A4",
    name: "A4",
    type: "Blanche",
    image: "/assets/images/blanche/A4.jpg",
    file: "/assets/audios/LA4croche.mp3"
  },
  {
    id: 182,
    title: "B4",
    name: "B4",
    type: "Blanche",
    image: "/assets/images/blanche/B4.jpg",
    file: "/assets/audios/SI4croche.mp3"
  },
  {
    id: 183,
    title: "Gb2",
    name: "Gb2",
    type: "Blanche",
    image: "/assets/images/blanche/Gb2.jpg",
    file: "/assets/audios/SOLb2croche.mp3"
  },
  {
    id: 184,
    title: "Ab2",
    name: "Ab2",
    type: "Blanche",
    image: "/assets/images/blanche/Ab2.jpg",
    file: "/assets/audios/LAb2croche.mp3"
  },
  {
    id: 185,
    title: "Bb2",
    name: "Bb2",
    type: "Blanche",
    image: "/assets/images/blanche/Bb2.jpg",
    file: "/assets/audios/SIb2croche.mp3"
  },
  {
    id: 186,
    title: "Cb3",
    name: "Cb3",
    type: "Blanche",
    image: "/assets/images/blanche/Cb3.jpg",
    file: "/assets/audios/DOb3croche.mp3"
  },
  {
    id: 187,
    title: "Db3",
    name: "Db3",
    type: "Blanche",
    image: "/assets/images/blanche/Db3.jpg",
    file: "/assets/audios/REb3croche.mp3"
  },
  {
    id: 188,
    title: "Eb3",
    name: "Eb3",
    type: "Blanche",
    image: "/assets/images/blanche/Eb3.jpg",
    file: "/assets/audios/MIb3croche.mp3"
  },
  {
    id: 189,
    title: "Fb3",
    name: "Fb3",
    type: "Blanche",
    image: "/assets/images/blanche/Fb3.jpg",
    file: "/assets/audios/FAb3croche.mp3"
  },
  {
    id: 190,
    title: "Gb3",
    name: "Gb3",
    type: "Blanche",
    image: "/assets/images/blanche/Gb3.jpg",
    file: "/assets/audios/SOLb3croche.mp3"
  },
  {
    id: 191,
    title: "Ab3",
    name: "Ab3",
    type: "Blanche",
    image: "/assets/images/blanche/Ab3.jpg",
    file: "/assets/audios/LAb3croche.mp3"
  },
  {
    id: 192,
    title: "Bb3",
    name: "Bb3",
    type: "Blanche",
    image: "/assets/images/blanche/Bb3.jpg",
    file: "/assets/audios/SIb3croche.mp3"
  },
  {
    id: 193,
    title: "Cb4",
    name: "Cb4",
    type: "Blanche",
    image: "/assets/images/blanche/Cb4.jpg",
    file: "/assets/audios/DOb4croche.mp3"
  },
  {
    id: 194,
    title: "Db4",
    name: "Db4",
    type: "Blanche",
    image: "/assets/images/blanche/Db4.jpg",
    file: "/assets/audios/REb4croche.mp3"
  },
  {
    id: 195,
    title: "Eb4",
    name: "Eb4",
    type: "Blanche",
    image: "/assets/images/blanche/Eb4.jpg",
    file: "/assets/audios/MIb4croche.mp3"
  },
  {
    id: 196,
    title: "Fb4",
    name: "Fb4",
    type: "Blanche",
    image: "/assets/images/blanche/Fb4.jpg",
    file: "/assets/audios/FAb4croche.mp3"
  },
  {
    id: 197,
    title: "Gb4",
    name: "Gb4",
    type: "Blanche",
    image: "/assets/images/blanche/Gb4.jpg",
    file: "/assets/audios/SOLb4croche.mp3"
  },
  {
    id: 198,
    title: "Ab4",
    name: "Ab4",
    type: "Blanche",
    image: "/assets/images/blanche/Ab4.jpg",
    file: "/assets/audios/LAb4croche.mp3"
  },
  {
    id: 199,
    title: "Bb4",
    name: "Bb4",
    type: "Blanche",
    image: "/assets/images/blanche/Bb4.jpg",
    file: "/assets/audios/SIb4croche.mp3"
  },
  {
    id: 200,
    title: "G#2",
    name: "G#2",
    type: "Blanche",
    image: "/assets/images/blanche/GHash2.jpg",
    file: "/assets/audios/SOLd2croche.mp3"
  },
  {
    id: 201,
    title: "A#2",
    name: "A#2",
    type: "Blanche",
    image: "/assets/images/blanche/AHash2.jpg",
    file: "/assets/audios/LAd2croche.mp3"
  },
  {
    id: 202,
    title: "B#2",
    name: "B#2",
    type: "Blanche",
    image: "/assets/images/blanche/BHash2.jpg",
    file: "/assets/audios/SId2croche.mp3"
  },
  {
    id: 203,
    title: "C#3",
    name: "C#3",
    type: "Blanche",
    image: "/assets/images/blanche/CHash3.jpg",
    file: "/assets/audios/DOd3croche.mp3"
  },
  {
    id: 204,
    title: "D#3",
    name: "D#3",
    type: "Blanche",
    image: "/assets/images/blanche/DHash3.jpg",
    file: "/assets/audios/REd3croche.mp3"
  },
  {
    id: 205,
    title: "E#3",
    name: "E#3",
    type: "Blanche",
    image: "/assets/images/blanche/EHash3.jpg",
    file: "/assets/audios/MId3croche.mp3"
  },
  {
    id: 206,
    title: "F#3",
    name: "F#3",
    type: "Blanche",
    image: "/assets/images/blanche/FHash3.jpg",
    file: "/assets/audios/FAd3croche.mp3"
  },
  {
    id: 207,
    title: "G#3",
    name: "G#3",
    type: "Blanche",
    image: "/assets/images/blanche/GHash3.jpg",
    file: "/assets/audios/SOLd3croche.mp3"
  },
  {
    id: 208,
    title: "A#3",
    name: "A#3",
    type: "Blanche",
    image: "/assets/images/blanche/AHash3.jpg",
    file: "/assets/audios/LAd3croche.mp3"
  },
  {
    id: 209,
    title: "B#3",
    name: "B#3",
    type: "Blanche",
    image: "/assets/images/blanche/BHash3.jpg",
    file: "/assets/audios/SId3croche.mp3"
  },
  {
    id: 210,
    title: "C#4",
    name: "C#4",
    type: "Blanche",
    image: "/assets/images/blanche/CHash4.jpg",
    file: "/assets/audios/DOd4croche.mp3"
  },
  {
    id: 211,
    title: "D#4",
    name: "D#4",
    type: "Blanche",
    image: "/assets/images/blanche/DHash4.jpg",
    file: "/assets/audios/REd4croche.mp3"
  },
  {
    id: 212,
    title: "E#4",
    name: "E#4",
    type: "Blanche",
    image: "/assets/images/blanche/EHash4.jpg",
    file: "/assets/audios/MId4croche.mp3"
  },
  {
    id: 213,
    title: "F#4",
    name: "F#4",
    type: "Blanche",
    image: "/assets/images/blanche/FHash4.jpg",
    file: "/assets/audios/FAd4croche.mp3"
  },
  {
    id: 214,
    title: "G#4",
    name: "G#4",
    type: "Blanche",
    image: "/assets/images/blanche/GHash4.jpg",
    file: "/assets/audios/SOLd4croche.mp3"
  },
  {
    id: 215,
    title: "A#4",
    name: "A#4",
    type: "Blanche",
    image: "/assets/images/blanche/AHash4.jpg",
    file: "/assets/audios/LAd4croche.mp3"
  },
  {
    id: 216,
    title: "B#4",
    name: "B#4",
    type: "Blanche",
    image: "/assets/images/blanche/BHash4.jpg",
    file: "/assets/audios/SId4croche.mp3"
  },
  /* Blanche Pointée */
  {
      id: 217,
      title: "G2",
      name: "G2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/G2.jpg",
      file: "/assets/audios/G2blanche-pointée.mp3"
    },
    {
      id: 218,
      title: "A2",
      name: "A2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/A2.jpg",
      file: "/assets/audios/A2blanche-pointée.mp3"
    },
    {
      id: 219,
      title: "B2",
      name: "B2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/B2.jpg",
      file: "/assets/audios/B2blanche-pointée.mp3"
    },
    {
      id: 220,
      title: "C3",
      name: "C3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/C3.jpg",
      file: "/assets/audios/C3blanche-pointée.mp3"
    },
    {
      id: 221,
      title: "D3",
      name: "D3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/D3.jpg",
      file: "/assets/audios/D3blanche-pointée.mp3"
    },
    {
      id: 222,
      title: "E3",
      name: "E3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/E3.jpg",
      file: "/assets/audios/E3blanche-pointée.mp3"
    },
    {
      id: 223,
      title: "F3",
      name: "F3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/F3.jpg",
      file: "/assets/audios/F3blanche-pointée.mp3"
    },
    {
      id: 224,
      title: "G3",
      name: "G3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/G3.jpg",
      file: "/assets/audios/G3blanche-pointée.mp3"
    },
    {
      id: 225,
      title: "A3",
      name: "A3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/A3.jpg",
      file: "/assets/audios/A3blanche-pointée.mp3"
    },
    {
      id: 226,
      title: "B3",
      name: "B3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/B3.jpg",
      file: "/assets/audios/B3blanche-pointée.mp3"
    },
    {
      id: 227,
      title: "C4",
      name: "C4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/C4.jpg",
      file: "/assets/audios/C4blanche-pointée.mp3"
    },
    {
      id: 228,
      title: "D4",
      name: "D4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/D4.jpg",
      file: "/assets/audios/D4blanche-pointée.mp3"
    },
    {
      id: 229,
      title: "E4",
      name: "E4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/E4.jpg",
      file: "/assets/audios/E4blanche-pointée.mp3"
    },
    {
      id: 230,
      title: "F4",
      name: "F4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/F4.jpg",
      file: "/assets/audios/F4blanche-pointée.mp3"
    },
    {
      id: 231,
      title: "G4",
      name: "G4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/G4.jpg",
      file: "/assets/audios/G4blanche-pointée.mp3"
    },
    {
      id: 232,
      title: "A4",
      name: "A4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/A4.jpg",
      file: "/assets/audios/A4blanche-pointée.mp3"
    },
    {
      id: 233,
      title: "B4",
      name: "B4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/B4.jpg",
      file: "/assets/audios/B4blanche-pointée.mp3"
    },
    {
      id: 234,
      title: "Gb2",
      name: "Gb2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Gb2.jpg",
      file: "/assets/audios/G2bblanche-pointée.mp3"
    },
    {
      id: 235,
      title: "Ab2",
      name: "Ab2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Ab2.jpg",
      file: "/assets/audios/A2bblanche-pointée.mp3"
    },
    {
      id: 236,
      title: "Bb2",
      name: "Bb2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Bb2.jpg",
      file: "/assets/audios/B2bblanche-pointée.mp3"
    },
    {
      id: 237,
      title: "Cb3",
      name: "Cb3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Cb3.jpg",
      file: "/assets/audios/C3bblanche-pointée.mp3"
    },
    {
      id: 238,
      title: "Db3",
      name: "Db3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Db3.jpg",
      file: "/assets/audios/D3bblanche-pointée.mp3"
    },
    {
      id: 239,
      title: "Eb3",
      name: "Eb3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Eb3.jpg",
      file: "/assets/audios/E3bblanche-pointée.mp3"
    },
    {
      id: 240,
      title: "Fb3",
      name: "Fb3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Fb3.jpg",
      file: "/assets/audios/F3bblanche-pointée.mp3"
    },
    {
      id: 241,
      title: "Gb3",
      name: "Gb3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Gb3.jpg",
      file: "/assets/audios/G3bblanche-pointée.mp3"
    },
    {
      id: 242,
      title: "Ab3",
      name: "Ab3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Ab3.jpg",
      file: "/assets/audios/A3bblanche-pointée.mp3"
    },
    {
      id: 243,
      title: "Bb3",
      name: "Bb3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Bb3.jpg",
      file: "/assets/audios/B3bblanche-pointée.mp3"
    },
    {
      id: 244,
      title: "Cb4",
      name: "Cb4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Cb4.jpg",
      file: "/assets/audios/C4bblanche-pointée.mp3"
    },
    {
      id: 245,
      title: "Db4",
      name: "Db4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Db4.jpg",
      file: "/assets/audios/D4bblanche-pointée.mp3"
    },
    {
      id: 246,
      title: "Eb4",
      name: "Eb4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Eb4.jpg",
      file: "/assets/audios/E4bblanche-pointée.mp3"
    },
    {
      id: 247,
      title: "Fb4",
      name: "Fb4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Fb4.jpg",
      file: "/assets/audios/F4bblanche-pointée.mp3"
    },
    {
      id: 248,
      title: "Gb4",
      name: "Gb4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Gb4.jpg",
      file: "/assets/audios/G4bblanche-pointée.mp3"
    },
    {
      id: 249,
      title: "Ab4",
      name: "Ab4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Ab4.jpg",
      file: "/assets/audios/A4bblanche-pointée.mp3"
    },
    {
      id: 250,
      title: "Bb4",
      name: "Bb4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/Bb4.jpg",
      file: "/assets/audios/B4bblanche-pointée.mp3"
    },
    {
      id: 251,
      title: "G#2",
      name: "G#2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/GHash2.jpg",
      file: "/assets/audios/Gd2blanche-pointée.mp3"
    },
    {
      id: 252,
      title: "A#2",
      name: "A#2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/AHash2.jpg",
      file: "/assets/audios/A2dblanche-pointée.mp3"
    },
    {
      id: 253,
      title: "B#2",
      name: "B#2",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/BHash2.jpg",
      file: "/assets/audios/B2dblanche-pointée.mp3"
    },
    {
      id: 254,
      title: "C#3",
      name: "C#3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/CHash3.jpg",
      file: "/assets/audios/C3dblanche-pointée.mp3"
    },
    {
      id: 255,
      title: "D#3",
      name: "D#3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/DHash3.jpg",
      file: "/assets/audios/D3dblanche-pointée.mp3"
    },
    {
      id: 256,
      title: "E#3",
      name: "E#3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/EHash3.jpg",
      file: "/assets/audios/E3dblanche-pointée.mp3"
    },
    {
      id: 257,
      title: "F#3",
      name: "F#3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/FHash3.jpg",
      file: "/assets/audios/F3dblanche-pointée.mp3"
    },
    {
      id: 258,
      title: "G#3",
      name: "G#3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/GHash3.jpg",
      file: "/assets/audios/G3dblanche-pointée.mp3"
    },
    {
      id: 259,
      title: "A#3",
      name: "A#3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/AHash3.jpg",
      file: "/assets/audios/A3dblanche-pointée.mp3"
    },
    {
      id: 260,
      title: "B#3",
      name: "B#3",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/BHash3.jpg",
      file: "/assets/audios/B3dblanche-pointée.mp3"
    },
    {
      id: 261,
      title: "C#4",
      name: "C#4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/CHash4.jpg",
      file: "/assets/audios/C4dblanche-pointée.mp3"
    },
    {
      id: 262,
      title: "D#4",
      name: "D#4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/DHash4.jpg",
      file: "/assets/audios/D4dblanche-pointée.mp3"
    },
    {
      id: 263,
      title: "E#4",
      name: "E#4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/EHash4.jpg",
      file: "/assets/audios/E4dblanche-pointée.mp3"
    },
    {
      id: 264,
      title: "F#4",
      name: "F#4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/FHash4.jpg",
      file: "/assets/audios/F4dblanche-pointée.mp3"
    },
    {
      id: 265,
      title: "G#4",
      name: "G#4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/GHash4.jpg",
      file: "/assets/audios/G4dblanche-pointée.mp3"
    },
    {
      id: 266,
      title: "A#4",
      name: "A#4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/AHash4.jpg",
      file: "/assets/audios/A4dblanche-pointée.mp3"
    },
    {
      id: 267,
      title: "B#4",
      name: "B#4",
      type: "Blanche Pointée",
      image: "/assets/images/blanche_pointee/BHash4.jpg",
      file: "/assets/audios/B4dblanche-pointée.mp3"
    },
  /* Rondes */
  {
      id: 268,
      title: "G2",
      name: "G2",
      type: "Ronde",
      image: "/assets/images/rondes/G2.jpeg",
      file: "/assets/audios/G2ronde.mp3"
    },
    {
      id: 269,
      title: "A2",
      name: "A2",
      type: "Ronde",
      image: "/assets/images/rondes/A2.jpeg",
      file: "/assets/audios/A2ronde.mp3"
    },
    {
      id: 270,
      title: "B2",
      name: "B2",
      type: "Ronde",
      image: "/assets/images/rondes/B2.jpeg",
      file: "/assets/audios/B2ronde.mp3"
    },
    {
      id: 271,
      title: "C3",
      name: "C3",
      type: "Ronde",
      image: "/assets/images/rondes/C3.jpeg",
      file: "/assets/audios/C3ronde.mp3"
    },
    {
      id: 272,
      title: "D3",
      name: "D3",
      type: "Ronde",
      image: "/assets/images/rondes/D3.jpeg",
      file: "/assets/audios/D3ronde.mp3"
    },
    {
      id: 273,
      title: "E3",
      name: "E3",
      type: "Ronde",
      image: "/assets/images/rondes/E3.jpeg",
      file: "/assets/audios/E3ronde.mp3"
    },
    {
      id: 274,
      title: "F3",
      name: "F3",
      type: "Ronde",
      image: "/assets/images/rondes/F3.jpeg",
      file: "/assets/audios/F3ronde.mp3"
    },
    {
      id: 275,
      title: "G3",
      name: "G3",
      type: "Ronde",
      image: "/assets/images/rondes/G3.jpeg",
      file: "/assets/audios/G3ronde.mp3"
    },
    {
      id: 276,
      title: "A3",
      name: "A3",
      type: "Ronde",
      image: "/assets/images/rondes/A3.jpeg",
      file: "/assets/audios/A3ronde.mp3"
    },
    {
      id: 277,
      title: "B3",
      name: "B3",
      type: "Ronde",
      image: "/assets/images/rondes/B3.jpeg",
      file: "/assets/audios/B3ronde.mp3"
    },
    {
      id: 278,
      title: "C4",
      name: "C4",
      type: "Ronde",
      image: "/assets/images/rondes/C4.jpeg",
      file: "/assets/audios/C4ronde.mp3"
    },
    {
      id: 279,
      title: "D4",
      name: "D4",
      type: "Ronde",
      image: "/assets/images/rondes/D4.jpeg",
      file: "/assets/audios/D4ronde.mp3"
    },
    {
      id: 280,
      title: "E4",
      name: "E4",
      type: "Ronde",
      image: "/assets/images/rondes/E4.jpeg",
      file: "/assets/audios/E4ronde.mp3"
    },
    {
      id: 281,
      title: "F4",
      name: "F4",
      type: "Ronde",
      image: "/assets/images/rondes/F4.jpeg",
      file: "/assets/audios/F4ronde.mp3"
    },
    {
      id: 282,
      title: "G4",
      name: "G4",
      type: "Ronde",
      image: "/assets/images/rondes/G4.jpeg",
      file: "/assets/audios/G4ronde.mp3"
    },
    {
      id: 283,
      title: "A4",
      name: "A4",
      type: "Ronde",
      image: "/assets/images/rondes/A4.jpeg",
      file: "/assets/audios/A4ronde.mp3"
    },
    {
      id: 284,
      title: "B4",
      name: "B4",
      type: "Ronde",
      image: "/assets/images/rondes/B4.jpeg",
      file: "/assets/audios/B4ronde.mp3"
    },
    {
      id: 285,
      title: "Gb2",
      name: "Gb2",
      type: "Ronde",
      image: "/assets/images/rondes/Gb2.jpeg",
      file: "/assets/audios/G2bronde.mp3"
    },
    {
      id: 286,
      title: "Ab2",
      name: "Ab2",
      type: "Ronde",
      image: "/assets/images/rondes/Ab2.jpeg",
      file: "/assets/audios/A2bronde.mp3"
    },
    {
      id: 287,
      title: "Bb2",
      name: "Bb2",
      type: "Ronde",
      image: "/assets/images/rondes/Bb2.jpeg",
      file: "/assets/audios/B2bronde.mp3"
    },
    {
      id: 288,
      title: "Cb3",
      name: "Cb3",
      type: "Ronde",
      image: "/assets/images/rondes/Cb3.jpeg",
      file: "/assets/audios/C3bronde.mp3"
    },
    {
      id: 289,
      title: "Db3",
      name: "Db3",
      type: "Ronde",
      image: "/assets/images/rondes/Db3.jpeg",
      file: "/assets/audios/D3bronde.mp3"
    },
    {
      id: 290,
      title: "Eb3",
      name: "Eb3",
      type: "Ronde",
      image: "/assets/images/rondes/Eb3.jpeg",
      file: "/assets/audios/E3bronde.mp3"
    },
    {
      id: 291,
      title: "Fb3",
      name: "Fb3",
      type: "Ronde",
      image: "/assets/images/rondes/Fb3.jpeg",
      file: "/assets/audios/F3bronde.mp3"
    },
    {
      id: 292,
      title: "Gb3",
      name: "Gb3",
      type: "Ronde",
      image: "/assets/images/rondes/Gb3.jpeg",
      file: "/assets/audios/G3bronde.mp3"
    },
    {
      id: 293,
      title: "Ab3",
      name: "Ab3",
      type: "Ronde",
      image: "/assets/images/rondes/Ab3.jpeg",
      file: "/assets/audios/A3bronde.mp3"
    },
    {
      id: 294,
      title: "Bb3",
      name: "Bb3",
      type: "Ronde",
      image: "/assets/images/rondes/Bb3.jpeg",
      file: "/assets/audios/B3bronde.mp3"
    },
    {
      id: 295,
      title: "Cb4",
      name: "Cb4",
      type: "Ronde",
      image: "/assets/images/rondes/Cb4.jpeg",
      file: "/assets/audios/C4bronde.mp3"
    },
    {
      id: 296,
      title: "Db4",
      name: "Db4",
      type: "Ronde",
      image: "/assets/images/rondes/Db4.jpeg",
      file: "/assets/audios/D4bronde.mp3"
    },
    {
      id: 297,
      title: "Eb4",
      name: "Eb4",
      type: "Ronde",
      image: "/assets/images/rondes/Eb4.jpeg",
      file: "/assets/audios/E4bronde.mp3"
    },
    {
      id: 298,
      title: "Fb4",
      name: "Fb4",
      type: "Ronde",
      image: "/assets/images/rondes/Fb4.jpeg",
      file: "/assets/audios/F4bronde.mp3"
    },
    {
      id: 290,
      title: "Gb4",
      name: "Gb4",
      type: "Ronde",
      image: "/assets/images/rondes/Gb4.jpeg",
      file: "/assets/audios/G4bronde.mp3"
    },
    {
      id: 291,
      title: "Ab4",
      name: "Ab4",
      type: "Ronde",
      image: "/assets/images/rondes/Ab4.jpeg",
      file: "/assets/audios/A4bronde.mp3"
    },
    {
      id: 292,
      title: "Bb4",
      name: "Bb4",
      type: "Ronde",
      image: "/assets/images/rondes/Bb4.jpeg",
      file: "/assets/audios/B4bronde.mp3"
    },
    {
      id: 293,
      title: "G#2",
      name: "G#2",
      type: "Ronde",
      image: "/assets/images/rondes/GHash2.jpeg",
      file: "/assets/audios/G2dronde.mp3"
    },
    {
      id: 294,
      title: "A#2",
      name: "A#2",
      type: "Ronde",
      image: "/assets/images/rondes/AHash2.jpeg",
      file: "/assets/audios/A2dronde.mp3"
    },
    {
      id: 295,
      title: "B#2",
      name: "B#2",
      type: "Ronde",
      image: "/assets/images/rondes/BHash2.jpeg",
      file: "/assets/audios/B2dronde.mp3"
    },
    {
      id: 296,
      title: "C#3",
      name: "C#3",
      type: "Ronde",
      image: "/assets/images/rondes/CHash3.jpeg",
      file: "/assets/audios/C3dronde.mp3"
    },
    {
      id: 297,
      title: "D#3",
      name: "D#3",
      type: "Ronde",
      image: "/assets/images/rondes/DHash3.jpeg",
      file: "/assets/audios/D3dronde.mp3"
    },
    {
      id: 298,
      title: "E#3",
      name: "E#3",
      type: "Ronde",
      image: "/assets/images/rondes/EHash3.jpeg",
      file: "/assets/audios/E3dronde.mp3"
    },
    {
      id: 299,
      title: "F#3",
      name: "F#3",
      type: "Ronde",
      image: "/assets/images/rondes/FHash3.jpeg",
      file: "/assets/audios/F3dronde.mp3"
    },
    {
      id: 300,
      title: "G#3",
      name: "G#3",
      type: "Ronde",
      image: "/assets/images/rondes/GHash3.jpeg",
      file: "/assets/audios/G3dronde.mp3"
    },
    {
      id: 301,
      title: "A#3",
      name: "A#3",
      type: "Ronde",
      image: "/assets/images/rondes/AHash3.jpeg",
      file: "/assets/audios/A3dronde.mp3"
    },
    {
      id: 302,
      title: "B#3",
      name: "B#3",
      type: "Ronde",
      image: "/assets/images/rondes/BHash3.jpeg",
      file: "/assets/audios/B3dronde.mp3"
    },
    {
      id: 303,
      title: "C#4",
      name: "C#4",
      type: "Ronde",
      image: "/assets/images/rondes/CHash4.jpeg",
      file: "/assets/audios/C4dronde.mp3"
    },
    {
      id: 304,
      title: "D#4",
      name: "D#4",
      type: "Ronde",
      image: "/assets/images/rondes/DHash4.jpeg",
      file: "/assets/audios/D4dronde.mp3"
    },
    {
      id: 305,
      title: "E#4",
      name: "E#4",
      type: "Ronde",
      image: "/assets/images/rondes/EHash4.jpeg",
      file: "/assets/audios/E4dronde.mp3"
    },
    {
      id: 306,
      title: "F#4",
      name: "F#4",
      type: "Ronde",
      image: "/assets/images/rondes/FHash4.jpeg",
      file: "/assets/audios/F4dronde.mp3"
    },
    {
      id: 307,
      title: "G#4",
      name: "G#4",
      type: "Ronde",
      image: "/assets/images/rondes/GHash4.jpeg",
      file: "/assets/audios/G4dronde.mp3"
    },
    {
      id: 308,
      title: "A#4",
      name: "A#4",
      type: "Ronde",
      image: "/assets/images/rondes/AHash4.jpeg",
      file: "/assets/audios/A4dronde.mp3"
    },
    {
      id: 309,
      title: "B#4",
      name: "B#4",
      type: "Ronde",
      image: "/assets/images/rondes/BHash4.jpeg",
      file: "/assets/audios/B4dronde.mp3"
    },
  /* Noire Pointée */
  {
      id: 310,
      title: "G2 noire pointée",
      name: "G2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/G2.jpg",
      file: "/assets/audios/G2noire-pointee.mp3"
    },
    {
      id: 311,
      title: "A2 noire pointée",
      name: "A2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/A2.jpg",
      file: "/assets/audios/A2noire-pointee.mp3"
    },
    {
      id: 312,
      title: "B2 noire pointée",
      name: "B2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/B2.jpg",
      file: "/assets/audios/B2noire-pointee.mp3"
    },
    {
      id: 313,
      title: "C3 noire pointée",
      name: "C3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/C3.jpg",
      file: "/assets/audios/C3noire-pointee.mp3"
    },
    {
      id: 314,
      title: "D3 noire pointée",
      name: "D3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/D3.jpg",
      file: "/assets/audios/D3noire-pointee.mp3"
    },
    {
      id: 315,
      title: "E3 noire pointée",
      name: "E3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/E3.jpg",
      file: "/assets/audios/E3noire-pointee.mp3"
    },
    {
      id: 316,
      title: "F3 noire pointée",
      name: "F3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/F3.jpg",
      file: "/assets/audios/F3noire-pointee.mp3"
    },
    {
      id: 317,
      title: "G3 noire pointée",
      name: "G3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/G3.jpg",
      file: "/assets/audios/G3noire-pointee.mp3"
    },
    {
      id: 318,
      title: "A3 noire pointée",
      name: "A3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/A3.jpg",
      file: "/assets/audios/A3noire-pointee.mp3"
    },
    {
      id: 319,
      title: "B3 noire pointée",
      name: "B3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/B3.jpg",
      file: "/assets/audios/B3noire-pointee.mp3"
    },
    {
      id: 320,
      title: "C4 noire pointée",
      name: "C4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/C4.jpg",
      file: "/assets/audios/C4noire-pointee.mp3"
    },
    {
      id: 321,
      title: "D4 noire pointée",
      name: "D4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/D4.jpg",
      file: "/assets/audios/D4noire-pointee.mp3"
    },
    {
      id: 322,
      title: "E4 noire pointée",
      name: "E4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/E4.jpg",
      file: "/assets/audios/E4noire-pointee.mp3"
    },
    {
      id: 323,
      title: "F4 noire pointée",
      name: "F4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/F4.jpg",
      file: "/assets/audios/F4noire-pointee.mp3"
    },
    {
      id: 324,
      title: "G4 noire pointée",
      name: "G4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/G4.jpg",
      file: "/assets/audios/G4noire-pointee.mp3"
    },
    {
      id: 325,
      title: "A4 noire pointée",
      name: "A4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/A4.jpg",
      file: "/assets/audios/A4noire-pointee.mp3"
    },
    {
      id: 326,
      title: "B4 noire pointée",
      name: "B4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/B4.jpg",
      file: "/assets/audios/B4noire-pointee.mp3"
    },
    {
      id: 327,
      title: "G2b noire pointée",
      name: "Gb2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Gb2.jpg",
      file: "/assets/audios/G2bnoire-pointee.mp3"
    },
    {
      id: 328,
      title: "A2b noire pointée",
      name: "Ab2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Ab2.jpg",
      file: "/assets/audios/A2bnoire-pointee.mp3"
    },
    {
      id: 329,
      title: "B2b noire pointée",
      name: "Bb2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Bb2.jpg",
      file: "/assets/audios/B2bnoire-pointee.mp3"
    },
    {
      id: 330,
      title: "C3b noire pointée",
      name: "Cb3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Cb3.jpg",
      file: "/assets/audios/C3bnoire-pointee.mp3"
    },
    {
      id: 331,
      title: "D3b noire pointée",
      name: "Db3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Db3.jpg",
      file: "/assets/audios/D3bnoire-pointee.mp3"
    },
    {
      id: 332,
      title: "E3b noire pointée",
      name: "Eb3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Eb3.jpg",
      file: "/assets/audios/E3bnoire-pointee.mp3"
    },
    {
      id: 333,
      title: "F3b noire pointée",
      name: "Fb3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Fb3.jpg",
      file: "/assets/audios/F3bnoire-pointee.mp3"
    },
    {
      id: 334,
      title: "G3b noire pointée",
      name: "Gb3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Gb3.jpg",
      file: "/assets/audios/G3bnoire-pointee.mp3"
    },
    {
      id: 335,
      title: "A3b noire pointée",
      name: "Ab3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Ab3.jpg",
      file: "/assets/audios/A3bnoire-pointee.mp3"
    },
    {
      id: 336,
      title: "B3b noire pointée",
      name: "Bb3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Bb3.jpg",
      file: "/assets/audios/B3bnoire-pointee.mp3"
    },
    {
      id: 337,
      title: "C4b noire pointée",
      name: "Cb4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Cb4.jpg",
      file: "/assets/audios/C4bnoire-pointee.mp3"
    },
    {
      id: 338,
      title: "D4b noire pointée",
      name: "Db4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Db4.jpg",
      file: "/assets/audios/D4bnoire-pointee.mp3"
    },
    {
      id: 339,
      title: "E4b noire pointée",
      name: "Eb4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Eb4.jpg",
      file: "/assets/audios/E4bnoire-pointee.mp3"
    },
    {
      id: 340,
      title: "F4b noire pointée",
      name: "Fb4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Fb4.jpg",
      file: "/assets/audios/F4bnoire-pointee.mp3"
    },
    {
      id: 341,
      title: "G4b noire pointée",
      name: "Gb4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Gb4.jpg",
      file: "/assets/audios/G4bnoire-pointee.mp3"
    },
    {
      id: 342,
      title: "A4b noire pointée",
      name: "Ab4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Ab4.jpg",
      file: "/assets/audios/A4bnoire-pointee.mp3"
    },
    {
      id: 343,
      title: "B4b noire pointée",
      name: "Bb4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/Bb4.jpg",
      file: "/assets/audios/B4bnoire-pointee.mp3"
    },
    {
      id: 344,
      title: "G#2 noire pointée",
      name: "G#2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/GHash2.jpg",
      file: "/assets/audios/Gd2noire-pointee.mp3"
    },
    {
      id: 345,
      title: "A#2 noire pointée",
      name: "A#2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/AHash2.jpg",
      file: "/assets/audios/Ad2noire-pointee.mp3"
    },
    {
      id: 346,
      title: "B#2 noire pointée",
      name: "B#2",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/BHash2.jpg",
      file: "/assets/audios/Bd2noire-pointee.mp3"
    },
    {
      id: 347,
      title: "C#3 noire pointée",
      name: "C#3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/CHash3.jpg",
      file: "/assets/audios/Cd3noire-pointee.mp3"
    },
    {
      id: 348,
      title: "D#3 noire pointée",
      name: "D#3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/DHash3.jpg",
      file: "/assets/audios/Dd3noire-pointee.mp3"
    },
    {
      id: 349,
      title: "E#3 noire pointée",
      name: "E#3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/EHash3.jpg",
      file: "/assets/audios/Ed3noire-pointee.mp3"
    },
    {
      id: 350,
      title: "F#3 noire pointée",
      name: "F#3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/FHash3.jpg",
      file: "/assets/audios/Fd3noire-pointee.mp3"
    },
    {
      id: 351,
      title: "G#3 noire pointée",
      name: "G#3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/GHash3.jpg",
      file: "/assets/audios/Gd3noire-pointee.mp3"
    },
    {
      id: 352,
      title: "A#3 noire pointée",
      name: "A#3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/AHash3.jpg",
      file: "/assets/audios/Ad3noire-pointee.mp3"
    },
    {
      id: 353,
      title: "B#3 noire pointée",
      name: "B#3",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/BHash3.jpg",
      file: "/assets/audios/Bd3noire-pointee.mp3"
    },
    {
      id: 354,
      title: "C#4 noire pointée",
      name: "C#4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/CHash4.jpg",
      file: "/assets/audios/Cd4noire-pointee.mp3"
    },
    {
      id: 355,
      title: "D#4 noire pointée",
      name: "D#4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/DHash4.jpg",
      file: "/assets/audios/Dd4noire-pointee.mp3"
    },
    {
      id: 356,
      title: "E#4 noire pointée",
      name: "E#4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/EHash4.jpg",
      file: "/assets/audios/Ed4noire-pointee.mp3"
    },
    {
      id: 357,
      title: "F#4 noire pointée",
      name: "F#4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/FHash4.jpg",
      file: "/assets/audios/Fd4noire-pointee.mp3"
    },
    {
      id: 358,
      title: "G#4 noire pointée",
      name: "G#4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/GHash4.jpg",
      file: "/assets/audios/Gd4noire-pointee.mp3"
    },
    {
      id: 359,
      title: "A#4 noire pointée",
      name: "A#4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/AHash4.jpg",
      file: "/assets/audios/Ad4noire-pointee.mp3"
    },
    {
      id: 360,
      title: "B#4 noire pointée",
      name: "B#4",
      type: "Noire Pointée",
      image: "/assets/images/noire_pointee/BHash4.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    /* Lettres */
    {
      id: 361,
      title: "DO",
      name: "DO",
      type: "Lettre",
      image: "/assets/images/lettre/do.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 362,
      title: "RE",
      name: "RE",
      type: "Lettre",
      image: "/assets/images/lettre/re.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 363,
      title: "MI",
      name: "MI",
      type: "Lettre",
      image: "/assets/images/lettre/mi.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 364,
      title: "FA",
      name: "FA",
      type: "Lettre",
      image: "/assets/images/lettre/fa.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 365,
      title: "SOL",
      name: "SOL",
      type: "Lettre",
      image: "/assets/images/lettre/sol.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 366,
      title: "LA",
      name: "LA",
      type: "Lettre",
      image: "/assets/images/lettre/la.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
     {
      id: 367,
      title: "SI",
      name: "SI",
      type: "Lettre",
      image: "/assets/images/lettre/si.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 368,
      title: "DO #",
      name: "DO #",
      type: "Lettre",
      image: "/assets/images/lettre/dod.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 369,
      title: "RE #",
      name: "RE #",
      type: "Lettre",
      image: "/assets/images/lettre/red.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 370,
      title: "MI #",
      name: "MI #",
      type: "Lettre",
      image: "/assets/images/lettre/mid.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 371,
      title: "FA #",
      name: "FA #",
      type: "Lettre",
      image: "/assets/images/lettre/fad.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 372,
      title: "SOL #",
      name: "SOL #",
      type: "Lettre",
      image: "/assets/images/lettre/sold.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 373,
      title: "LA #",
      name: "LA #",
      type: "Lettre",
      image: "/assets/images/lettre/lad.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 374,
      title: "SI #",
      name: "SI #",
      type: "Lettre",
      image: "/assets/images/lettre/sid.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 375,
      title: "DO b",
      name: "DO b",
      type: "Lettre",
      image: "/assets/images/lettre/dob.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 376,
      title: "RE b",
      name: "RE b",
      type: "Lettre",
      image: "/assets/images/lettre/reb.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 377,
      title: "MI b",
      name: "MI b",
      type: "Lettre",
      image: "/assets/images/lettre/mib.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 378,
      title: "FA b",
      name: "FA b",
      type: "Lettre",
      image: "/assets/images/lettre/fab.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 379,
      title: "SOL b",
      name: "SOL b",
      type: "Lettre",
      image: "/assets/images/lettre/solb.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 380,
      title: "LA b",
      name: "LA b",
      type: "Lettre",
      image: "/assets/images/lettre/lab.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },
    {
      id: 381,
      title: "SI b",
      name: "SI b",
      type: "Lettre",
      image: "/assets/images/lettre/sib.jpg",
      file: "/assets/audios/Bd4noire-pointee.mp3"
    },

 ];

export default melodyNotes;
