import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  /* apiKey: "AIzaSyDAuaixE_zQHNCgB15cEb_C_T6Tiob_W4o",
  authDomain: "midi-react-app.firebaseapp.com",
  projectId: "midi-react-app",
  storageBucket: "midi-react-app.appspot.com",
  messagingSenderId: "351563419787",
  appId: "1:351563419787:web:db199a323041ab2dd3c48e" */
  apiKey: "AIzaSyAT-GTEPtfjoTI2eZtnBtJ2CaErlRqv2Fk",
  authDomain: "strum-notes.firebaseapp.com",
  projectId: "strum-notes",
  storageBucket: "strum-notes.appspot.com",
  messagingSenderId: "559313132856",
  appId: "1:559313132856:web:5ab27284bf1d26da28f5cd"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const storage = getStorage(app);

