import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom'
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from 'react-redux'
import { selectNotes, setConvertNotes, selectConvertNote, selectStrumString, setSturmNotes, addStrum, setURL, getURL, removeNote } from 'store/store'
import { useNavigate } from "react-router-dom";
import _ from 'lodash'
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import MidiModal from 'components/modal';
import { Modal, Button } from 'react-bootstrap';
import melodyNotes from 'data/melodyNotes'
import * as yup from 'yup'
//import { yupResolver } from '@hookform/resolvers/yup'
import { storage } from 'config/firebase'
import { getDownloadURL, ref, uploadBytesResumable, uploadBytes } from 'firebase/storage';
import { Midi } from '@tonejs/midi';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { RiDeleteBin6Line } from 'react-icons/ri'
import { AiOutlineHome } from "react-icons/ai";
import { MdContentCopy } from 'react-icons/md';

const CardItemsContainer = styled.div`
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        padding-bottom: 30px;
        user-select: none;
        border: none;
        overflow-x: hidden;
        svg { 
            display: none;
        }
        .react-contextmenu-wrapper{
            &:hover{
                background: aliceblue;
                border: 1px solid #000;
            }
        }
    }

`;
const CardItemsTitle = styled.legend`
    font-size: 1.5rem;
    font-weight: 700;
    color: #9c9c9c;
    text-align: center;
    line-height: 1.125;
    margin-bottom: 1.25rem;
`;

const TrackImage = styled.img`
  width: 100%;
  max-width: 100px;
  height: auto;

`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 1.5rem 2.5rem;
    &>button{
        margin: 20px;
    }
`;

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-direction: column;
    img {
        width: 15rem;
    }
`;

const options = [
    {
        label: 'Croche',
        value: 'Croche',
    },
    {
        label: 'Croche Pointée',
        value: 'Croche Pointée',
    },
    {
        label: 'Blanche',
        value: 'Blanche',
    },
    {
        label: 'Blanche pointée',
        value: 'Blanche pointée',
    },
    {
        label: 'Ronde',
        value: 'ronde',
    },
    {
        label: 'Noire pointée',
        value: 'Noire pointée',
    },
];
type Option = typeof options;

function ListTracks() {

    const [tracks, setTracks] = useState<any>([]);
    const [trackGenerate, setTrackGenerate] = useState<string[]>();
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [newTracks, setNewTracks] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [notesSelections, setNotesSelections] = useState<string>('Croche');


    const sturmNotes = useSelector(selectNotes);
    const strumSelectNotes = useSelector(selectConvertNote)
    const strumString = useSelector(selectStrumString)
    const dispatch = useDispatch();
    const url = useSelector(getURL);

     useEffect(() => {
         _.map((sturmNotes), (track) => {
             setTracks(track);
         })
     }, [sturmNotes])

    /* useEffect(() => {
        const initializedTracks = sturmNotes.map((track, index) => ({
            ...track,
            id: Date.now() + index
        }));
        setTracks(initializedTracks);
    }, [sturmNotes]); */

console.log("traks", tracks)
    useEffect(() => {
        const getValues: any = trackGenerate?.toString();
        dispatch(setConvertNotes(tracks));
        dispatch(addStrum(getValues));
    }, [dispatch, tracks, trackGenerate])

    /*     useEffect(() => {
           const strumStringValues: any = strumString?.toString();
            dispatch(setSturmNotes(trackGenerate + strumStringValues));
        },[strumString, trackGenerate]); */

    const navigate = useNavigate();



    const onSortEnd = ({ oldIndex, newIndex }: any) => {
        setTracks(arrayMoveImmutable(tracks, oldIndex, newIndex));
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const newNotes = value.split(' ');

        setTrackGenerate(newNotes)
    }

    const handleGenerate = () => {
        trackGenerate?.forEach((note) => {
            const strum = melodyNotes.find(o => o.title === note);
            if (strum) {
                setNewTracks((prevState: any) => [...prevState, { src: strum.image, width: 4, height: 3 }])
                setTracks((prevState: any) => [...prevState, { src: strum.image, width: 4, height: 3 }])
                setShow(false);
            } else {
                console.log(`${note} Note not found.`);
            }
        })
    }

    const getMidi = async (uri: any) => {
        // load a midi file in the browser
        //note.midi, note.time, note.duration, note.name
        //the file name decoded from the first track
        //get the tracks   
        //notes are an array

        const midi = await Midi.fromUrl(uri)
        const name = midi.name
        midi.tracks.forEach(track => {
            const notes = track.notes
            notes.forEach(note => {
                console.log("Note Name ===>", note.name);
                const strum = melodyNotes.find(o => o.title === note.name)
                if (strum) {
                    //((prevState: any) => [...prevState, { src: strum.image, width: 4, height: 3 }])
                    setTracks((prevState: any) => [...prevState, { src: strum.image, width: 4, height: 3 }])
                } else {
                    console.log(`${note} Note not found.`);
                }
                setLoading(false)
            })
        })
    }

    const [progress, setProgress] = useState(0);
    const [URI, setURI] = useState<any>();
    useEffect(() => {
        dispatch(setURL(URI))
    }, [dispatch, URI])

    const {
        register,
        handleSubmit,
    } = useForm();
    const [fileUpload, setFileUpload] = useState(null);


    const onSubmit = (data: any) => {
        const file = data.file[0];
        const storageRef = ref(storage, `/files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file)
        uploadTask.on("state_changed", (snapshot) => {
            const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
            setProgress(prog)
        }, (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => getMidi(url))
                setShowModal(false)
                setLoading(true)
            }
        );

    }
    /* const upladFile = (file: any) => {
        if(file) return;
        const fileRef = ref(storage, `/files/${file.name}`);
        uploadBytes(fileRef, file).then(() => {
            alert('Uploaded File')
        })
    } */
    const upladFile = (file: any) => {
        if (!file) {
            const storageRef = ref(storage, `/files/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file)
            uploadTask.on("state_changed", (snapshot) => {
                const prog = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                setProgress(prog)
            }, (err) => console.log(err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => console.log(url))
                }
            );
        }
    }



    const [uniqueId] = useState(
        () => 'select_' + Math.random().toFixed(5).slice(2),
    );

    const [selectedItemId, setSelectedItemId] = useState(null);


    /*  const handleRemoveClick = (e: any, data: any, target: any) => {
         const nextNoteItems = tracks.filter((noteItem: any) => noteItem.id !== selectedItemId);
         setTracks(nextNoteItems);
         dispatch(setSturmNotes(nextNoteItems))
     } */

    const duplicateTrack = (item: any) => {
        const newId = Date.now() + Math.random();
        return { ...item, id: newId };
    };

    /*  const handleRemoveClick = () => {
         const filteredTracks = tracks.filter((item: any) => item.id !== selectedItemId);
         setTracks(filteredTracks);
         dispatch(setSturmNotes(filteredTracks))
     }; */

    const handleRemoveClick = () => {
        const filteredTracks = tracks.filter((item: any) => item.id !== selectedItemId);
        setTracks(filteredTracks);
    };


    const handleDuplicateClick = () => {
        const itemToDuplicate = tracks.find((item: any) => item.id === selectedItemId);
        if (itemToDuplicate) {
            const duplicatedItem = duplicateTrack(itemToDuplicate);
            setTracks([...tracks, duplicatedItem]);
        }
    };



    const SortableItem = SortableElement(({ item }: any) => (
        <TrackImage src={item.src} alt="" />
    ));



    const SortableList = SortableContainer(({ items }: any) => (
        <>
            <div className="container">
                {items.map((item: any, index: number) => (
                    <ContextMenuTrigger key={item.id} id="contextmenu">
                        <div onContextMenu={() => setSelectedItemId(item.id)}>
                            <SortableItem index={index} item={item} />
                        </div>
                    </ContextMenuTrigger>
                ))}
            </div>

            <ContextMenu id="contextmenu">
                <MenuItem onClick={handleRemoveClick}>
                    <RiDeleteBin6Line className="delete" />
                    <span>Supprimer</span>
                </MenuItem>
                <MenuItem onClick={handleDuplicateClick}>
                    <MdContentCopy className='duplicate' />
                    <span>Dupliquer</span>
                </MenuItem>
            </ContextMenu>

        </>

    ));


    return (
        <>
            {/* <CardItemsTitle>Séctionner les notes</CardItemsTitle> */}
            {!loading ? (
                <>
                    <CardItemsContainer>
                        <SortableList items={tracks} onSortEnd={onSortEnd} axis={"xy"} helperclassName="SortableHelper" />
                    </CardItemsContainer>
                    <ButtonContainer>
                        <ButtonContainer>
                            <Link to='/convert-notes'>
                                <Button variant="danger" size="lg">
                                    CONVERTIR
                                </Button>
                            </Link>
                        </ButtonContainer>
                        <Button variant="success" size="lg" onClick={handleShow}>
                            Générer les fichiers images
                        </Button>
                        <Button variant="primary" size="lg" onClick={handleShowModal}>
                            Upload Fichier Mid
                        </Button>
                    </ButtonContainer>
                    <ButtonContainer>
                        <Button variant="secondary" size="lg" onClick={() => navigate(-1)}>
                            <AiOutlineHome size={30} />
                        </Button>
                    </ButtonContainer>
                </>
            ) : (
                <LoaderContainer>
                    <img src="assets/images/loading.gif" alt="" />
                    <h5>Chargement...</h5>
                </LoaderContainer>
            )}
            {show && <MidiModal show={show} handleClose={handleClose} onChange={handleChange} onClick={handleGenerate} />}
            {showModal && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Header className="border-0 pb-0" closeButton>
                            <h5>Télécharger le fichier MIDI</h5>
                        </Modal.Header>
                        <Modal.Body>
                            <input {...register('file')} type="file" name="file" id="upload" />
                        </Modal.Body>
                        <Modal.Footer>

                            <Button type="submit" variant="success">Générer</Button>
                        </Modal.Footer>
                    </form>
                </Modal>)
            }
        </>

    )
}

interface ModalProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    show?: boolean;
    handleClose?: () => void;
    onClick?: () => void;
    onSubmit?: (data: any) => void;
    ref?: any
}

function UploadMidiModal(props: ModalProps) {

    const { show, handleClose, onSubmit, ref } = props

    return (

        <Modal show={show} onHide={handleClose}>
            <form onSubmit={onSubmit}>
                <Modal.Header className="border-0 pb-0" closeButton>
                    <h1>Strum playground</h1>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input
                            ref={ref}
                            type="file"
                            name="file"
                            id="fileupload"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>

                    {/* <Button onClick={handleClose}>Quiter</Button> */}
                    {/* {isUploaded && <h3>Télecharger {progress} %</h3>} */}
                    <Button type="submit" variant="success">Générer</Button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}




export default ListTracks
